import { query, getClient, mutate } from '../../apollo/client';
import { removeTypenames } from '../../apollo/client';

export async function deleteMission({ projectId, missionId }) {
  const { default: deleteProjectMissionQuery } = await import('../../graphql/mission/deleteProjectMission');

  return mutate(deleteProjectMissionQuery, { projectId, missionId }, false);
}

export async function publishOrUpdateMission(variables) {
  const { default: publishOrUpdateMission } = await import('../../graphql/mission/publishOrUpdateMission');

  const response = await mutate(publishOrUpdateMission, { ...variables });
  return response.data.publishOrUpdateMission;
}

export async function addOrUpdateDraftMission(variables) {
  const { default: addOrUpdateDraftMission } = await import('../../graphql/mission/addOrUpdateDraftMission');

  const response = await mutate(addOrUpdateDraftMission, { ...variables });
  return response.data.addOrUpdateDraftMission;
}

export async function applyMission(missionId, coverLetter) {
  const { default: applyQuery } = await import('../../graphql/mission/apply');

  const response = await mutate(applyQuery, { missionId, coverLetter });
  return response.data.apply;
}

export async function unapplyMission(missionId) {
  const { default: unapplyQuery } = await import('../../graphql/mission/unapply');
  await mutate(unapplyQuery, { missionId });
}

export async function unapplyMissionWhileOnGoing(missionId, form) {
  const { default: unapplyWhileOnGoingQuery } = await import('../../graphql/mission/unapplyWhileOnGoing');

  const response = await mutate(unapplyWhileOnGoingQuery, { missionId, form });
  return response.data.unapplyWhileOnGoing;
}

export async function pauseOrStartMission(variables) {
  const { default: pauseOrStartMissionQuery } = await import('../../graphql/mission/pauseOrStartMission');
  const response = await mutate(pauseOrStartMissionQuery, variables);

  return response.data.pauseOrStartMission;
}

export async function matchingRoles() {
  const { default: getMatchingRolesQuery } = await import('../../graphql/mission/getMatchingRoles');

  return query(getMatchingRolesQuery);
}

export async function getMissionById(id, token) {
  const { default: getMissionByIdQuery } = await import('../../graphql/search/getMissionById');

  const response = await getClient(token).query({
    query: getMissionByIdQuery,
    variables: { missionId: id },
    fetchPolicy: 'no-cache'
  });

  return removeTypenames(response.data.getMissionById);
}

export async function getMissionBySlugWithCompany(slugWithCompany, token) {
  const { default: getMissionBySlugWithCompanyQuery } = await import('../../graphql/search/getPublicMissionBySlugWithCompany');

  const response = await getClient(token).query({
    query: getMissionBySlugWithCompanyQuery,
    variables: { slugWithCompany },
    fetchPolicy: 'no-cache'
  });

  return removeTypenames(response.data.getPublicMissionBySlugWithCompany);
}

export async function getSlugsWithCompany() {
  const { default: getSlugsWithCompanyQuery } = await import(
    '../../graphql/mission/getPublicSlugsWithCompany'
  );
  const response = await query(getSlugsWithCompanyQuery);

  return response.data.getPublicSlugsWithCompany;
}

export async function searchMissions({ searchValue, locations, role, orderBy, page, isPaginated, currency, minDailyRate }, token) {
  const { default: getMissionsQuery } = await import('../../graphql/search/getMissions');

  const response = await getClient(token).query({
    query: getMissionsQuery,
    variables: {
      searchValue,
      locations,
      role,
      orderBy,
      page,
      isPaginated,
      currency,
      minDailyRate
    },
    fetchPolicy: 'no-cache'
  });

  return {
    missions: removeTypenames(response.data.getMissions.data),
    totalCount: removeTypenames(response.data.getMissions.totalCount)
  };
}

export async function getAppliedMissions() {
  const { default: getAppliedMissionsQuery } = await import('../../graphql/mission/getAppliedMissions');

  const response = await query(getAppliedMissionsQuery);

  return response.data.getAppliedMissions;
}

/**
 * @param {string} role
 * @return {import('../../components/search/missionsMvp/type').Location[]}
 */
export async function getMissionLocations({ role }) {
  const { default: getMissionRegionsQuery } = await import('../../graphql/search/getMissionRegions');

  const response = await query(getMissionRegionsQuery, { role });

  return response.data.getMissionRegions;
}

export async function getMatchingRoles() {
  const { default: getMatchingRolesQuery } = await import('../../graphql/mission/getMatchingRoles');

  const response = await query(getMatchingRolesQuery);

  return response.data.getMatchingRoles;
}

/**
 * @param {string} locations
 * @return {import('../../components/search/missionsMvp/type').Location[]}

 */
export async function getRoles({ locations }) {
  const { default: getRolesQuery } = await import('../../graphql/mission/getRoles');

  const response = await query(getRolesQuery, { locations });

  return response.data.getRoles;
}

/**
 * Skill type definition
 * @typedef Skill
 * @type {object}
 * @property {string} id
 * @property {number} level
 */

/**
 * @param {Skill[]} skills
 * @param {Skill[]} [languages]
 */
export async function getSkillsMatching(skills, languages = []) {
  const { default: getSkillsMatchingQuery } = await import('../../graphql/mission/getSkillsMatching');

  const response = await query(getSkillsMatchingQuery, { skills, languages });

  return response.data.getSkillsMatching;
}

export async function getSuggestedSkills(description) {
  const { default: getSuggestedSkillsQuery } = await import('../../graphql/mission/getSuggestedSkills');

  const response = await query(getSuggestedSkillsQuery, { description });

  return response.data.getSuggestedSkills;
}
