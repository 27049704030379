import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { darken, lighten, transparentize } from 'polished';
import { space, borderRadius, variant, layout, color, typography, flexbox, border, system } from 'styled-system';
import { pick } from 'ramda';
import { oneOfType, number, node, string, func, shape, bool, object, oneOf } from 'prop-types';

import Loading from './icons/Loading';

const errorShake = keyframes({
  '0%': { transform: 'translate(30px)' },
  '20%': { transform: 'translate(-30px)' },
  '40%': { transform: 'translate(15px)' },
  '60%': { transform: 'translate(-15px)' },
  '80%': { transform: 'translate(8px)' },
  '100%': { transform: 'translate(0px)' }
});

const StyledButton = styled('button')(
  system({ whiteSpace: true }),
  ({ theme, iconOnly, display, variant }) => ({
    transition: 'all .3s ease',
    display: 'inline-block',
    position: 'relative',
    fontWeight: 700,
    appearance: 'none',
    border: 0,
    lineHeight: '24px',
    cursor: 'pointer',
    borderRadius: theme.radii[2],
    padding: variant === 'small' ? '' : `${theme.space[1]}px ${theme.space[3]}px`,
    boxShadow: '0 0 0 0 transparent inset, 0 0 0 0 transparent',
    whiteSpace: 'nowrap',
    width: display === 'block' ? '100%' : 'auto',
    animation: () => {
      css`
        ${errorShake} 0.4s linear
      `;
    },
    '&:disabled': {
      background: theme.colors.greyVerylight,
      color: theme.colors.grey,
      pointerEvents: 'none',
      cursor: 'default'
    },
    '&:active': {
      transform: 'scale(.95)'
    },
    '& svg': {
      fontSize: '18px',
      display: 'inline-block',
      marginRight: iconOnly ? 0 : '8px',
      marginBottom: '2px',
      verticalAlign: 'middle'
    }
  }),
  ({ theme }) =>
    variant({
      prop: 'styleType',
      variants: {
        primary: {
          color: theme.colors.dark,
          backgroundColor: theme.colors.mainLight,
          '&:hover': {
            backgroundColor: lighten(0.1, theme.colors.mainLight)
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${theme.colors.main} inset, 0 0 0 10px ${transparentize(0.8, theme.colors.mainLight)}`
          }
        },
        secondary: {
          color: theme.colors.white,
          backgroundColor: theme.colors.dark,
          '&:hover': {
            color: theme.colors.white,
            backgroundColor: lighten(0.1, theme.colors.dark)
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${darken(0.1, theme.colors.dark)} inset, 0 0 0 10px ${transparentize(
              0.8,
              theme.colors.dark
            )}`
          }
        },
        tertiary: {
          color: theme.colors.dark,
          backgroundColor: theme.colors.greyVerylight,
          '&:hover': {
            backgroundColor: darken(0.1, theme.colors.greyVerylight)
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${darken(0.1, theme.colors.greyVerylight)} inset, 0 0 0 10px ${transparentize(
              0.8,
              theme.colors.greyLight
            )}`
          }
        },
        white: {
          color: theme.colors.dark,
          backgroundColor: theme.colors.white,
          '&:hover': {
            backgroundColor: theme.colors.greyVerylight
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${theme.colors.greyVerylight} inset, 0 0 0 10px ${transparentize(
              0.8,
              theme.colors.greyLight
            )}`
          }
        },
        error: {
          color: theme.colors.white,
          backgroundColor: theme.colors.red,
          '&:hover': {
            backgroundColor: lighten(0.1, theme.colors.red)
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${darken(0.1, theme.colors.red)} inset, 0 0 0 10px ${transparentize(
              0.8,
              theme.colors.red
            )}`
          }
        },
        danger: {
          color: theme.colors.red,
          backgroundColor: theme.colors.greyVerylight,
          '&:hover': {
            backgroundColor: darken(0.1, theme.colors.greyVerylight)
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${darken(0.1, theme.colors.greyVerylight)} inset, 0 0 0 10px ${transparentize(
              0.8,
              theme.colors.greyLight
            )}`
          }
        },
        warning: {
          color: theme.colors.secondary,
          backgroundColor: theme.colors.greyVerylight,
          '&:hover': {
            backgroundColor: darken(0.1, theme.colors.greyVerylight)
          },
          '&:focus': {
            boxShadow: `0 0 0 2px ${darken(0.1, theme.colors.greyVerylight)} inset, 0 0 0 10px ${transparentize(
              0.8,
              theme.colors.greyLight
            )}`
          }
        },
        transparent: {
          color: theme.colors.black,
          background: 'none',
          padding: `4px ${theme.space[2]}px`,
          ':hover, &:focus': {
            backgroundColor: transparentize(0.5, theme.colors.greyVerylight)
          },
          '&:disabled': {
            background: 'transparent'
          }
        },
        transparentWithBorder: {
          color: theme.colors.black,
          background: 'none',
          whiteSpace: 'normal',
          padding: '1px 4px 1px 10px',
          border: `1px solid ${theme.colors.greyVerylight}`,
          borderRadius: '6px',
          ':hover, &:focus': {
            backgroundColor: theme.colors.greyVerylight
          },
          '&:disabled': {
            background: 'transparent'
          }
        },
        openWork: {
          color: theme.colors.white,
          backgroundColor: theme.colors.openwork,
          '&:hover': {
            backgroundColor: darken(0.1, theme.colors.openwork)
          },
          '&:disabled': {
            opacity: '0.5'
          },
          '& svg': {
            margin: 0
          }
        },
        beelanceServices: {
          color: theme.colors.white,
          backgroundColor: theme.colors.beelanceServices,
          '&:hover': {
            backgroundColor: darken(0.1, theme.colors.beelanceServices)
          },
          '&:disabled': {
            opacity: '0.5'
          },
          '& svg': {
            margin: 0
          }
        },
        securex: {
          color: theme.colors.white,
          backgroundColor: theme.colors.securex,
          '&:hover': {
            backgroundColor: darken(0.1, theme.colors.securex)
          },
          '&:disabled': {
            opacity: '0.5'
          },
          '& svg': {
            margin: 0,
            marginBottom: '-5px'
          }
        }
      }
    }),
  ({ theme }) =>
    variant({
      prop: 'shape',
      variants: {
        rounded: {
          fontSize: theme.fontSizes.baseline,
          padding: `${theme.space[2]}px ${theme.space[4]}px`,
          borderRadius: '100px'
        }
      }
    }),
  ({ theme }) =>
    variant({
      prop: 'size',
      variants: {
        sm: {
          fontSize: theme.fontSizes.xs,
          padding: `${theme.space[1]}px ${theme.space[2]}px`,
          '& svg': {
            margin: 0
          }
        }
      }
    }),
  borderRadius,
  space,
  layout,
  color,
  typography,
  flexbox,
  border
);

const rotate = keyframes({
  '0%': {
    transform: 'rotate(0)'
  },
  '100%': {
    transform: 'rotate(360deg)'
  }
});

const StyledLoading = styled(Loading)({
  animation: () =>
    css`
      ${rotate} 1.6s linear infinite normal
    `,
  verticalAlign: 'middle',
  fontSize: '16px'
});

const buttonAttributs = ['name', 'type', 'onClick', 'disabled', 'data-cy'];

const getButtonProps = props => pick(buttonAttributs, props);

const formatButtonProps = () => WrappedComponent => props => {
  return <WrappedComponent buttonProps={{ ...getButtonProps(props) }} {...props} />;
};

const Button = ({ children, buttonProps, loading, ...props }) => {
  return (
    <StyledButton {...buttonProps} {...props}>
      {loading && <StyledLoading data-cy="loading" />}
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: node,
  variant: oneOf(['classic', 'small']),
  type: string,
  shape: string,
  size: string,
  borderRadius: number,
  styleType: oneOfType([string, number, object]),
  getRef: func,
  loading: bool,
  buttonProps: shape({
    name: string,
    type: string,
    onClick: func,
    disabled: bool,
    iconOnly: bool
  })
};

Button.defaultProps = {
  type: 'button',
  styleType: 'primary',
  loading: false,
  variant: 'classic'
};

export default formatButtonProps()(Button);
