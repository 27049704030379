import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const HelpCircle = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 2.182c-5.422 0-9.818 4.396-9.818 9.818S6.578 21.818 12 21.818s9.818-4.396 9.818-9.818S17.422 2.182 12 2.182zM12 16a1 1 0 110 2 1 1 0 010-2zm1.18-10.935c2.21.37 3.823 2.246 3.82 4.438 0 1.438-.871 2.575-2.236 3.465a8.787 8.787 0 01-1.998.974 1.155 1.155 0 01-1.454-.711 1.116 1.116 0 01.602-1.374l.34-.128a6.932 6.932 0 001.235-.633c.79-.515 1.212-1.065 1.212-1.595.002-1.097-.805-2.034-1.91-2.22-1.043-.175-2.065.37-2.489 1.302l-.068.168c-.21.586-.867.894-1.466.688a1.118 1.118 0 01-.703-1.434c.744-2.068 2.905-3.31 5.115-2.94z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default HelpCircle;
