import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { checkCookie } from '../../../utils/cookies';
import COOKIES from '../../../constants/cookies';
import { getGoogleAnalyticsCode, getHotjarId, getHotjarVersion } from '../../../utils/config';
import cookie from 'react-cookies';
import { hotjar } from 'react-hotjar';
import { useRouter } from '../../../utils/router';

export const CookieContext = React.createContext(null);
export const useCookieContext = () => React.useContext(CookieContext);

export const useCookie = (cookieName, onAccept, onDecline) => {
  const [{ accepted, initialized }, setCookie] = useState({
    accepted: checkCookie(cookieName),
    initialized: false
  });
  if (accepted && !initialized) {
    onAccept(initialized);
    setCookie({ accepted, initialized: true });
  }

  return {
    accepted,
    initialized,
    accept: () => {
      if (accepted) return;
      onAccept(initialized);
      setCookie({ accepted: true, initialized: true });
    },
    initialize: () => setCookie({ accepted, initialized: true }),
    decline: () => {
      if (!accepted) return;
      onDecline();
      setCookie({ accepted: false, initialized });
    },
    canTrack: accepted && initialized
  };
};

const useBeelanceCookies = active => {
  const router = useRouter();
  const {
    canTrack: canTrackAnalytics,
    accept: acceptAnalytics,
    decline: declineAnalytics
  } = useCookie(
    COOKIES.ANALYTICS,
    alreadyInitialized => {
      if (!active) return;
      window[`ga-disable-${getGoogleAnalyticsCode()}`] = false;
      if (!alreadyInitialized) {
        ReactGA.initialize(getGoogleAnalyticsCode(), { cookieDomain: 'auto' });
      }
      ReactGA.pageview(window.location.pathname + window.location.search);
    },
    () => {
      if (!active) return;
      window[`ga-disable-${getGoogleAnalyticsCode()}`] = true;
    }
  );
  const {
    canTrack: canTrackHotjar,
    accept: acceptHotjar,
    decline: declineHotjar
  } = useCookie(
    COOKIES.HOTJAR,
    alreadyInitialized => {
      if (!active) return;
      if (!getHotjarId() || !getHotjarVersion()) {
        return;
      }
      if (!alreadyInitialized) {
        hotjar.initialize(getHotjarId(), getHotjarVersion());
      }
      cookie.save('_hjRecordingEnabled', true);
    },
    () => {
      if (!active) return;
      cookie.save('_hjRecordingEnabled', false);
    }
  );

  useEffect(() => {
    if (!active) return;
    const handleRouteChange = url => {
      if (canTrackAnalytics) {
        ReactGA.pageview(url);
        ReactGA.set({ page: url });
      }
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [active, canTrackAnalytics, router.events]);

  return {
    canTrackAnalytics,
    canTrackHotjar,
    enableOrDisableBasedOnCookie: () => {
      if (!active) return;
      if (checkCookie(COOKIES.HOTJAR)) {
        acceptHotjar();
      } else {
        declineHotjar();
      }

      if (checkCookie(COOKIES.ANALYTICS)) {
        acceptAnalytics();
      } else {
        declineAnalytics();
      }
    },
    acceptAll: () => {
      if (!active) return;
      acceptHotjar();
      acceptAnalytics();
    }
  };
};

// eslint-disable-next-line react/prop-types
export const CookieProvider = ({ children, active }) => {
  const { acceptAll, enableOrDisableBasedOnCookie, canTrackHotjar, canTrackAnalytics } = useBeelanceCookies(active);
  return (
    <CookieContext.Provider value={{ acceptAll, enableOrDisableBasedOnCookie, canTrackHotjar, canTrackAnalytics }}>
      {children}
    </CookieContext.Provider>
  );
};
