import colors from './colors';
import fontSizes from './fontSizes';
import space from './space';

const breakpoints = ['460px', '768px', '960px', '1280px', '1400px'];

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
breakpoints.xxl = breakpoints[4];

export default {
  breakpoints,
  space,
  radii: [6, 8, 12, 25, 30],
  dropdownIndex: 10,
  shadow: '0 10px 40px 0 rgba(0,0,0,0.10)',
  colors,
  fontSizes
};
