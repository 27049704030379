import { getNotifications, markNotificationAsRead } from '../notifications.service';
import { fetchFail, fetching, fetchSuccess } from '../../utils';

export default {
  state: {
    list: null,
    isLoaded: false,
    isLoadingList: false,
    pendings: null,
    isFetching: false,
    hasError: false
  },
  reducers: {
    loading: fetching,
    loadingFailed: fetchFail,
    loadingList: state => fetching({ ...state, list: [], isLoadingList: true }),
    setList: (state, list) => fetchSuccess({ ...state, list, isLoaded: true, isLoadingList: false }),
    loadingListFailed: state => fetchFail({ ...state, list: [], isLoadingList: false }),
    setTotalPendings: (state, pendings) => ({ ...state, pendings }),
    incrementTotalPendings: state => ({ ...state, pendings: state.pendings + 1 }),
    markNotificationAsRead: (state, notificationId) => {
      const list = state.list.map(n => (n.id === notificationId ? { ...n, seen: true } : n));
      const pendings = list.filter(n => !n.seen).length;

      return fetchSuccess({ ...state, pendings, list });
    }
  },
  effects: () => ({
    async loadList(_, rootState) {
      if (rootState.notifications.isFetching || rootState.notifications.isLoaded) {
        return;
      }
      this.loadingList();
      try {
        const notifications = await getNotifications();
        this.setList(notifications);
      } catch (e) {
        this.loadingListFailed();
      }
    },
    async reloadList() {
      this.setList(await getNotifications());
    },
    async readNotification(notificationId) {
      this.loading();
      try {
        await markNotificationAsRead(notificationId);
        this.markNotificationAsRead(notificationId);
      } catch (e) {
        this.loadingFailed();
      }
    }
  })
};
