export default Object.freeze({
  USER: Object.freeze({
    EMAIL: 'mail',
    COMPANY: 'company',
    PROFILE: 'profile',
    NOT_LOGGED: 'notLogged',
    PENDING: 'pending',
    PENDING_VAT: 'pending_vat',
    VERIFIED: 'verified'
  }),
  TASKORDER: Object.freeze({
    PENDING: 'pending',
    PENDING_UPLOAD: 'pending_upload',
    PENDING_VALIDATION: 'pending_validation',
    MODIFICATIONS_ASK: 'modifications_ask',
    SIGNED_COMPANY: 'signed_company',
    SIGNED_FREELANCE: 'signed_freelance',
    SIGNED: 'signed',
    REJECTED: 'rejected'
  }),
  TIMESHEET: Object.freeze({
    TO_APPROVE: 'to_approve',
    TO_CORRECT: 'to_correct',
    TO_SUBMIT: 'to_submit',
    APPROVED: 'approved'
  }),
  APPLICATION: Object.freeze({
    PENDING: 'pending',
    ONGOING: 'ongoing',
    HIRING: 'hiring',
    HIRED: 'hired',
    ARCHIVED: 'archived',
    REJECTED: 'rejected',
    APPLIED: 'applied'
  }),
  MISSION: Object.freeze({
    DRAFT: 0,
    PUBLISHED: 1,
    ARCHIVED: 2,
    PENDING: 3,
    AWAITING: 3
  }),
  MISSION_LABEL: Object.freeze({
    ON_GOING: 'ongoing',
    PUBLISHED: 'published',
    DRAFT: 'draft',
    PAST: 'past'
  }),
  ERRORS: Object.freeze({
    MISSION: {
      PUBLISH: {
        VAT: 'notEnteredVATMission',
        MAIL: 'notVerifiedEmailMission',
        COMPANY: 'notValidatedCompanyMission',
        MA: 'notSignedMAMission'
      }
    }
  }),
  SKILLS: Object.freeze({
    PENDING: 'Pending',
    APPROVED: 'Approved',
    REJECTED: 'Rejected'
  }),
  FREELANCE_LEGAL_DOCUMENT: Object.freeze({
    EMPTY: 'empty',
    IN_REVIEW: 'inReview',
    APPROVED: 'approved',
    REJECTED: 'rejected'
  })
});
