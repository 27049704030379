import * as Sentry from '@sentry/browser';

export function logException(e, moreExtra = {}) {
  Sentry.withScope((scope) => {
    scope.setExtra('error', e);
    scope.setExtra('whitePage', 'No');
    for (const [key, value] of Object.entries(moreExtra)) {
      scope.setExtra('m_' + key, value);
    }
    if (e instanceof Error) {
      Sentry.captureException(e);
    } else {
      try {
        let message = typeof e === 'string' ? e : 'Error: ' + JSON.stringify(e);
        Sentry.captureMessage(message, 'error');
      } catch (jsonError) {
        Sentry.captureException(jsonError);
      }
    }
  });
}

export function logNonGraphQLException(e) {
  if (e?.graphQLErrors) {
    return;
  }
  logException(e);
}

export function logMessage(message, level) {
  if (typeof Sentry !== 'undefined') {
    // eslint-disable-next-line no-undef
    Sentry.captureMessage(message, level);
  }
}
