import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const File = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 1a1.01 1.01 0 01.25.031l.03.009c.03.009.061.02.091.031l.027.012a.914.914 0 01.195.112c.04.03.078.062.114.098l-.093-.082.011.009.082.073 7 7a1.006 1.006 0 01.21.309l.012.027c.012.03.022.06.031.091l.008.03A.921.921 0 0121 9l-.008-.126c.005.041.008.083.008.126v11a3 3 0 01-3 3H6a3 3 0 01-3-3V4a3 3 0 013-3zm-1 2H6a1 1 0 00-1 1v16a1 1 0 001 1h12a1 1 0 001-1V10h-6a1 1 0 01-.993-.883L12 9V3zm5.586 5L14 4.415V8h3.586z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default File;
