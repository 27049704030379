import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Suitcase = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4 22a3 3 0 01-3-3V9a3 3 0 013-3h3V5a3 3 0 012.824-2.995L10 2h4a3 3 0 012.995 2.824L17 5v1h3a3 3 0 012.995 2.824L23 9v10a3 3 0 01-3 3zM7 8H4a1 1 0 00-1 1v10a1 1 0 001 1h3V8zm8 0H9v12h6V8zm5 0h-3v12h3a1 1 0 00.993-.883L21 19V9a1 1 0 00-1-1zm-6-4h-4a1 1 0 00-.993.883L9 5v1h6V5a1 1 0 00-.883-.993L14 4z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Suitcase;
