import BEELANCE from '../constants/beelance';
import { is, clone, pickBy, isNil, complement } from 'ramda';

export const cdnize = path => {
  return `${BEELANCE.cdnUrl}${path}`;
};

export const assetize = path => {
  return `/assets${path}`;
};

export const formatLanguage = language => language.toUpperCase().substring(0, 2);

export const translateVariables = (variables, t) => {
  if (!variables) {
    return undefined;
  }
  return Object.keys(variables).reduce((acc, key) => {
    const { key: translationKey, message } = variables[key];
    acc[key] = translationKey ? t(translationKey) : message;
    return acc;
  }, {});
};

export const roundNbToNDecimals = decimalsAccepted => value => {
  const decimals = Number(decimalsAccepted);
  const number = Number(value);
  if (isNaN(number) || isNaN(decimals)) return 0;
  const factor = Math.pow(10, decimals);
  return Math.round(number * factor) / factor;
};

export const getFormattedFileSize = bytes => {
  if (isNaN(bytes)) return '';

  const bytesPerKiloByte = 1024;
  const bytesPerMegaByte = 1048576;
  const roundToOneDecimal = roundNbToNDecimals(1);

  if (bytes < bytesPerKiloByte) return `${roundToOneDecimal(bytes)}B`;
  if (bytes < bytesPerMegaByte) return `${roundToOneDecimal(bytes / bytesPerKiloByte)}KB`;
  return `${roundToOneDecimal(bytes / bytesPerMegaByte)}MB`;
};

export const stripHtml = html =>
  (html || '')
    .replace(/(<([^>]+)>)/gi, ' ')
    .replace(/ +(?= )/g, '')
    .replace(/&nbsp;/g, '')
    .replace(/&amp;/g, '&')
    .trim();

/**
 * @param {object} object
 * @returns {object}
 */
export const removeNullAndUndefinedAttribute = object => {
  return pickBy(complement(isNil), object);
};

/**
 * @param {number} date
 * @returns {string}
 */
export const formatDateMonthAndYear = (t, date) => {
  const timestampAgo = new Date().getTime() - date;
  const totalNumberOfMonth = timestampAgo / 2629800000;
  const numberOfYear = totalNumberOfMonth > 12 ? Math.floor(totalNumberOfMonth / 12) : 0;
  const numberOfMonth = numberOfYear > 0 ? Math.ceil(totalNumberOfMonth - numberOfYear * 12) : 0;

  if (totalNumberOfMonth <= 12) return `${Math.ceil(totalNumberOfMonth)} ${t('common:months')}`;

  return `${numberOfYear} ${
    numberOfYear === 1 ? t('companyProfile:membershipDateYear') : t('companyProfile:membershipDateYears')
  } 
      ${t('common:and')} ${numberOfMonth} ${t('common:months')}`;
};
