import {
  addIndex,
  any,
  compose,
  curry,
  either,
  filter,
  path,
  prop,
  isEmpty,
  isNil,
  has,
  is,
  map,
  mapObjIndexed,
  mergeWithKey,
  sequence
} from 'ramda';
import Either, { Left, Right } from 'data.either';
import BeelanceDate from './BeelanceDate';

const bullets = '\u2022\u2023\u25E6\u2043\u2219';
const irregularWithSpace = ' ';
const regexLetter = 'A-Za-z0-9$€¥?àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ';
const regexString = `^['${regexLetter}${irregularWithSpace} ${bullets}()?!'’\\-/&#"%@:+=*.,…;«»_\\r\\n]+$|^$`;
const regexName = "^[a-zA-Z\u00E0-\u00FC' -]{2,255}$";

/**
 * @param {string} type
 */
const isType = type => value => path(['constructor', 'name'], value) === type;

export const isObject = isType('Object');
export const isArray = isType('Array');

export const isEmptyOrIsNil = either(isEmpty, isNil);

/**
 * @param {number} min
 * @param {number} max
 * @param {number | string} value
 */
export const inputNumberBetween = curry((min, max, value) => Number(value) >= min && Number(value) <= max);

/**
 * @param {number} maxLength
 * @param {string | number} nb
 */
export const inputDecimalsLessThan = curry((maxLength, nb) => {
  const decimals = String(nb).split('.')[1];

  return !decimals || decimals.length < maxLength;
});

/**
 * @param {string} value
 */
export const getParsedValue = value => (is(String, value) ? JSON.parse(value || '{}') : value);

/**
 * @param {any} monthPickerValue
 */
export const isMonthNotEmpty = monthPickerValue => {
  const month = prop('month', monthPickerValue);

  return isNumberInRange(0, 11)(month);
};

/**
 * @param {any} monthPickerValue
 */
export const isYearNotEmpty = monthPickerValue => {
  const year = prop('year', monthPickerValue);

  return isNotEmpty(year);
};

/**
 * @param {string} values
 */
export const isYearNotEmptyOrOtherOk = values => (a, index) => {
  const isOther = path([index], values);

  return isOtherOk(isOther) || isYearNotEmpty(a);
};

/**
 * @param {string | number | object} a
 */
export const isNotEmpty = a => !!a && a.toString().trim().length > 0;

/**
 * @param {string} a
 */
export const isNumber = a => parseFloat(a) === +a;

/**
 * @param {number} a
 */
export const isInteger = a => a % 1 === 0;

/**
 * @param {number} a
 */
export const isIntergerOrHalf = a => isInteger(a / 0.5);

/**
 * @param {string} a
 */
export const isAnEmail = a =>
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/.test(a);

/**
 * @param {string} a
 */
export const isAPhone = a => /^[0-9+][0-9.\- ]{5,20}$/.test(a);

/**
 * @param {string} a
 */
export const hasNoNumber = a => /^[^0-9]+$/.test(a);

/**
 * @param {string} a
 */
export const hasAuthorizedChar = a => RegExp(regexString).test(a);

/**
 * @param {string} value
 */
export const hasAuthorizedCharAndNotNumber = value => hasAuthorizedChar(value) && hasNoNumber(value);

export const hasAuthorizedCharForName = value => RegExp(regexName).test(value);

export const isGreaterThanOrEqual = curry((len, a) => a >= len);

export const isLessThanOrEqual = curry((len, a) => a <= len);

/**
 * @param {string} value
 */
export const IsJsonString = value => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

/**
 * @param {number} min
 * @param {number} max
 */
export const isNumberInRange = (min, max) => a =>
  isNumber(a) && isLessThanOrEqual(max)(a) && isGreaterThanOrEqual(min)(a);

/**
 * @param {string} a
 */
export const isPassword = a => /(?=.*([A-Za-z])+.*)(?=.*\d+.*)(?=.{8,})/.test(a);

/**
 * @param {string | number } a
 */
export const isEqual = a => curry(b => a === b);

/**
 * @param { number } numberOfSkillRequired
 * @param { number } numberOfSkill
 */
export const hasEnoughtSkills = (numberOfSkillRequired, numberOfSkill) => () => numberOfSkill >= numberOfSkillRequired;

/**
 * @param { string | boolean } isOther
 */
export const isOtherOk = isOther => isOther === true || isOther === 'true';

export const isCallbackOrOtherOK = curry((callback, isOther, val) => isOtherOk(isOther) || callback(val));

export const isNotEmptyOrOtherOk = isCallbackOrOtherOK(isNotEmpty);

/**
 * @param { string | boolean } isOther
 * @param { number } min
 * @param { number } max
 */
export const isNumberInRangeOrOtherOk = (min, max, isOther) => isCallbackOrOtherOK(isNumberInRange(min, max), isOther);

/**
 * @param { string } isoDateRef
 */
export const isLater = isoDateRef => isoDateChecked => {
  const now = new Date();
  const dateRef = isoDateRef ? BeelanceDate.fromISOString(isoDateRef).getDate() : now;
  const dateChecked = isoDateChecked ? BeelanceDate.fromISOString(isoDateChecked).getDate() : now;
  return dateChecked > dateRef;
};

/**
 * @param { string } dateRef
 */
export const isLaterOrEqual = dateRef => dateChecked => dateRef === dateChecked || isLater(dateRef)(dateChecked);

/**
 * @param { any[] } areOther
 * @param { string } date
 */
export const isLaterThanOrOtherOk =
  (areOther, date = new BeelanceDate(new Date()).getISOString()) =>
    a =>
      any(x => isOtherOk(x), areOther) || isLater(date)(a);

/**
 * @param { object } object
 * @return { object }
 */
export const removeNullValues = object => {
  const result = map(val => (is(Object, val) ? removeNullValues(val) : val), object);

  return filter(x => !isNil(x), result);
};

/**
 * @param { any[] } pred
 * @return { any[] }
 */
const makePredicate = pred => a => {
  const [predFn, e] = pred;

  return is(Array, e) ? map(makePredicate, pred) : predFn(a.value, a.index) ? Right(a.value) : Left(e);
};

/**
 * @param { any[] } a
 * @return { any[] }
 */
const makePredicates = a =>
  map(x => {
    const [, e] = x;

    return is(Array, e) ? makePredicates(x) : makePredicate(x);
  }, a);

/**
 * @param { any[] } predicates
 * @return { any[] }
 */
const runPredicates = (predicates = []) => {
  const [value, validations] = predicates;
  return is(Object, value.value) && !(is(Date, value))
    ? map(runPredicates, value.value)
    : map(predFn => predFn(value), makePredicates(validations));
};

const validate = a => {
  return map(input => {
    if (isNil(input)) return false;
    const [value, validations] = input;
    return !is(Array, validations) ? map(validate, input) : runSequence([value, validations]);
  })(a);
};

const runSequence = compose(sequence(Either.of), runPredicates);

const errorObject = result => {
  const errorTag = errorMsg => errorMsg;
  if (!result) {
    return null;
  }

  return !result.value
    ? map(errorObject, result)
    : result.cata({
      Right: () => null,
      Left: errorTag
    });
};

const makeValidationObject = (a, b) => {
  return mergeWithKey((_, l, r) => {
    if (is(Array, l)) {
      return map(x => makeValidationObject(x, r), l);
    }

    return [l, r];
  })(a, b);
};

const getErrors = compose(validate, filter(isArray), makeValidationObject);

const pickSecond = x => map(y => (isArray(y) ? y[1] : pickSecond(y)), x);

const addArgsToValidationRules = wrapped => (args, t) => {
  return wrapped({ t: key => (key.startsWith('validation') ? t(key) : t(`validation:${key}`)), ...args });
};

/**
 * @param {{values : object, info : object, validationRules : object}} { values, info, validationRules }
 * @return { object }
 */
export const validateForm = ({ values, info, validationRules }, t) => {
  const valuesToValidate = filter(x => !isNil(x), values || {});
  const rules = addArgsToValidationRules(validationRules)(
    {
      ...valuesToValidate,
      ...info
    },
    t
  );

  const defaultValues = map(x => (is(Array, x) ? x[0] : []), rules);
  const rulesToApply = pickSecond(rules);
  const newValue = filterValuesByRules({ ...defaultValues, ...valuesToValidate }, rules);
  const errors = getErrors(newValue, rulesToApply);
  const mappedErrors = map(errorObject, errors);

  return removeNullValues(mappedErrors);
};

const mapObjIndexedWithIndex = addIndex(mapObjIndexed);
const mapWithIndex = addIndex(map);

const filterValuesByRules = (values, rules, indexNextLevel) => {
  return mapObjIndexedWithIndex((val, key, _, index) => {
    return is(Array, val)
      ? mapWithIndex((x, index) => filterValuesByRules(x, rules[key], index), val)
      : has(key, rules)
        ? { value: val, index: !isNil(indexNextLevel) ? indexNextLevel : index }
        : undefined;
  }, values);
};

/**
 * @param {string} str
 * @return {boolean}
 */
export const isValidVatFormat = vat => {
  return !vat || RegExp(vatRegex).test(formatVAT(vat));
};

/**
 * @param {string} vat
 * @returns {string}
 */
const formatVAT = vat => {
  let formattedVat = vat.replace(/[. ,:-]+/g, '').toUpperCase();
  const country = formattedVat.substr(0, 2);
  if (country === 'BE') {
    let companyNumber = formattedVat.substr(2);
    if (companyNumber.length === 9) {
      companyNumber = '0' + companyNumber;
    }
    return country + companyNumber;
  }
  return formattedVat;
};

const vatRegex =
  '\\b((AT)U[0-9]{8}|(BE)[0-9]{9,10}|(BG)[0-9]{9,10}|(CHE)[0-9]{9}(MWST|IVA|TVA)|(CY)[0-9]{8}[A-Z]{1}|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)([0-9A-Z][0-9]{7}[A-Z])|(ES)([A-Z][0-9]{7}[0-9A-Z])|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(GB)[0-9]{9}|(HU)[0-9]{8}|(HR)[0-9]{11}|(IE)[0-9A-Z]{8,9}|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[A-Z0-9]{0,2}|(NO)[0-9]{9}[A-Z]{2,3}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{8,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})\\b';
