import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Globe = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zM6.603 13.09H2.242a9.827 9.827 0 007.278 8.412 17.81 17.81 0 01-2.917-8.411zm15.155 0H17.4a17.783 17.783 0 01-2.919 8.412 9.826 9.826 0 007.277-8.411zm-6.545 0H8.79A15.6 15.6 0 0012 21.218a15.587 15.587 0 003.213-8.127zM9.519 2.498l-.171.046a9.827 9.827 0 00-7.106 8.366H6.6A17.783 17.783 0 019.52 2.498zM12 2.783a15.587 15.587 0 00-3.213 8.127h6.423A15.6 15.6 0 0012 2.782zm2.48-.285l.151.23a17.82 17.82 0 012.766 8.181h4.361a9.827 9.827 0 00-7.278-8.411z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Globe;
