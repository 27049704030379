import { mutate, query } from '../../apollo/client';
import uploadCompanyLogoQuery from '../../components/settings/queries/uploadCompanyLogo';

export async function deleteCompanyUser(email) {
  const { default: deleteCompanyUserMutation } = await import('../../graphql/teams/deleteCompanyUser');

  const response = await mutate(deleteCompanyUserMutation, { email }, false);
  return response.data.deleteCompanyUser;
}

export async function rejectCompanyUser(email) {
  const { default: rejectCompanyUserMutation } = await import('../../graphql/teams/rejectCompanyUser');

  const response = await mutate(rejectCompanyUserMutation, { email }, false);
  return response.data.rejectCompanyUser;
}

export async function updateUserRole(email, role) {
  const { default: updateCompanyUserMutation } = await import('../../graphql/teams/updateAndValidateCompanyRole');

  const response = await mutate(updateCompanyUserMutation, { email, role }, false);
  return response.data.updateAndValidateCompanyRole;
}

export async function getCompanyUsers() {
  const { default: getCompanyUsersQuery } = await import('../../graphql/teams/getCompanyUsers');

  const response = await query(getCompanyUsersQuery, null, false);
  return {
    pendingInvitationUsers: response.data.getCompanyUsers.pendingInvitationUsers,
    corporateUsers: response.data.getCompanyUsers.corporateUsers,
    users: response.data.getCompanyUsers.users
  };
}

export async function getCompanyBusinessSector() {
  const { default: getCompanyBusinessSectorQuery } = await import('../../graphql/settings/getCompanyBusinessSector');

  const response = await query(getCompanyBusinessSectorQuery, null, false);
  return response.data.getCompanyBusinessSector;
}

export async function linkUserToCompany(variables) {
  const { default: linkUserToCompanyMutation } = await import('../../graphql/company/linkUserToCompany');

  const response = await mutate(linkUserToCompanyMutation, { companyInfo: variables }, false);
  return response.data.linkUserToCompany;
}

export async function updateCompanyInfo(variables) {
  const { default: updateCompanyInfoMutation } = await import('../../graphql/company/updateCompanyInfo');

  const response = await mutate(updateCompanyInfoMutation, { companyInfo: variables }, false);
  return response.data.updateCompanyInfo;
}

export async function uploadCompanyLogo(file) {
  const response = await mutate(uploadCompanyLogoQuery, { file }, false);
  return response.data.uploadCompanyLogo;
}

export async function handleVat(vat) {
  const { default: getCompanyInfoFromVATQuery } = await import('../../graphql/settings/getCompanyInfoFromVAT');

  const response = await query(getCompanyInfoFromVATQuery, { vat });
  return response.data.getCompanyInfoFromVAT;
}

export async function requestToWorkWithOpenWork() {
  const { default: requestToWorkWithOpenWorkMutation } = await import(
    '../../graphql/settings/requestToWorkWithOpenWork'
  );

  const response = await mutate(requestToWorkWithOpenWorkMutation);
  return response.data.requestToWorkWithOpenWork;
}

export async function requestToWorkWithSmart({ activityNumber, memberNumber }) {
  const { default: requestToWorkWithSmartMutation } = await import('../../graphql/settings/requestToWorkWithSmart');

  const response = await mutate(requestToWorkWithSmartMutation, { umbrellaMember: { activityNumber, memberNumber } });
  return response.data.requestToWorkWithSmart;
}

export async function requestToWorkWithBeelanceServices() {
  const { default: requestToWorkWithBeelanceServicesMutation } = await import(
    '../../graphql/settings/requestToWorkWithBeelanceServices'
  );

  const response = await mutate(requestToWorkWithBeelanceServicesMutation);
  return response.data.requestToWorkWithBeelanceServices;
}

export async function getRequestedUmbrellaCompany() {
  const { default: getRequestedUmbrellaCompanyQuery } = await import(
    '../../graphql/settings/getRequestedUmbrellaCompany'
  );

  const response = await query(getRequestedUmbrellaCompanyQuery, null, false);
  return response.data.getRequestedUmbrellaCompany;
}
