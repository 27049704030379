import { query } from '../../apollo/client';
import gql from 'graphql-tag';

const getFrontendVersion = () =>
  query(
    gql`
        query getFrontendVersion {
          getFrontendVersion
          }
      `
  ).then(result => result.data.getFrontendVersion);

  export { getFrontendVersion };
