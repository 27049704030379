import { query, mutate } from '../../apollo/client';

export async function loadChannelById(channelId) {
  const { default: getChannelById } = await import('../../graphql/chat/getChannelById');

  const response = await query(getChannelById, { channelId });
  return response.data.getChannelById;
}

export async function loadChannels(isArchived, searchValue) {
  const { default: getChannels } = await import('../../graphql/chat/getChannels');

  const response = await query(getChannels, { isArchived, search: searchValue });
  return response.data.getChannels;
}

export async function sendMessage(channelId, channelMessage) {
  const { default: sendMessageQuery } = await import('../../graphql/chat/sendMessage');

  const response = await mutate(sendMessageQuery, { channelId, channelMessage });
  return response.data.sendMessage;
}

export async function markChannelAsRead(channelId) {
  const { default: readChannel } = await import('../../graphql/chat/readChannel');

  const response = await mutate(readChannel, { channelId });
  return response.data.sendMessage;
}

export async function archiveChannel(channelId) {
  const { default: archiveChannelQuery } = await import('../../graphql/chat/archiveChannel');

  const response = await mutate(archiveChannelQuery, { channelId });
  return response.data.sendMessage;
}

export async function getPendingMessagesCount() {
  const { default: getPendingMessagesCountQuery } = await import('../../graphql/chat/getPendingMessagesCount');

  const response = await query(getPendingMessagesCountQuery);
  return response.data.getPendingMessagesCount;
}
