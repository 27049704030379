import { prop, assocPath } from 'ramda';
import {
  MONTHLY_TIMESHEET,
  CURRENT_DAILY_TS,
  CURRENT_MONTHLY_TS,
  UPDATE_DAILY_TIMESHEET,
  SUBMIT_TIMESHEET,
  APPROVE_TIMESHEET,
  REJECT_TIMESHEET
} from './timesheet.actionTypes';
import { transferMonthlyTimesheet, getListName, getItemIndex } from '../../components/timesheet/utils';
import { ACTIONS } from '../../constants';

const { _SUCCESS, _FAILURE } = ACTIONS;

export const initialListsState = {
  toSubmit: [],
  toCorrect: [],
  toApprove: [],
  approved: []
};

export const initialState = {
  ...initialListsState,
  currentDaily: {
    companyId: null,
    projectId: null,
    missionId: null,
    dailyTimeSheet: null,
    selectedDays: [],
    extraAllowed: false,
    disabledDays: [],
    minDate: null,
    maxDate: null
  },
  currentMonthly: {
    companyId: null,
    projectId: null,
    missionId: null,
    status: null,
    id: null,
    actualHours: null,
    extraTime: null
  },
  isFetching: false,
  hasError: false
};

const timesheet = {
  [MONTHLY_TIMESHEET]: () => ({
    ...initialListsState,
    isFetching: true,
    hasError: false
  }),
  [`${MONTHLY_TIMESHEET}${_SUCCESS}`]: payload => {
    const { __typename: typenameIgnored, ...lists } = payload;
    return {
      ...lists,
      isFetching: false,
      hasError: false
    };
  },
  [`${MONTHLY_TIMESHEET}${_FAILURE}`]: () => ({
    ...initialListsState,
    isFetching: false,
    hasError: true
  }),
  [CURRENT_DAILY_TS]: (payload, { currentDaily }) => ({
    currentDaily: payload ? { ...currentDaily, ...payload } : initialState.currentDaily
  }),
  [CURRENT_MONTHLY_TS]: (payload, { currentMonthly }) => ({
    currentMonthly: payload ? { ...currentMonthly, ...payload } : initialState.currentMonthly
  }),
  [UPDATE_DAILY_TIMESHEET]: () => ({ isFetching: true, hasError: false }),
  [`${UPDATE_DAILY_TIMESHEET}${_SUCCESS}`]: ({ companyId, projectId, missionId, updatedMonthlyTS }, state) => {
    const listToUpdate = getListName(prop('status', updatedMonthlyTS));

    // Example of the expected output: [2, 'projects', 6, 'missions', 1, 'monthlyTimeSheets']
    let pathToMonthlyTS = [
      [companyId, 'projects'],
      [projectId, 'missions'],
      [missionId, 'monthlyTimeSheets']
    ].reduce((acc, [id, key]) => [...acc, getItemIndex(id, acc, state[listToUpdate]), key], []);
    // Replace the specific monthly timesheet of the list to update
    const updatedList = assocPath(pathToMonthlyTS, [updatedMonthlyTS], state[listToUpdate]);

    return {
      [listToUpdate]: updatedList,
      isFetching: false,
      hasError: false
    };
  },
  [`${UPDATE_DAILY_TIMESHEET}${_FAILURE}`]: () => ({ isFetching: false, hasError: true }),
  [CURRENT_MONTHLY_TS]: (payload, { currentMonthly }) => ({
    currentMonthly: payload ? { ...currentMonthly, ...payload } : initialState.currentMonthly
  }),
  [SUBMIT_TIMESHEET]: () => ({ isFetching: true, hasError: false }),
  [`${SUBMIT_TIMESHEET}${_SUCCESS}`]: (
    { companyId, projectId, missionId, currentStatus, updatedStatus, translate },
    state
  ) => {
    const updatedAction = {
      level1Id: companyId,
      level2Id: projectId,
      level3Id: missionId,
      currentStatus,
      updatedStatus
    };
    return transferMonthlyTimesheet(true, 'submittedMessage', updatedAction, state, translate);
  },
  [`${SUBMIT_TIMESHEET}${_FAILURE}`]: () => ({ isFetching: false, hasError: true }),
  [APPROVE_TIMESHEET]: () => ({ isFetching: true, hasError: false }),
  [`${APPROVE_TIMESHEET}${_SUCCESS}`]: (
    { projectId, missionId, currentStatus, updatedStatus, idMts, translate },
    state
  ) => {
    const updatedAction = {
      level1Id: projectId,
      level2Id: missionId,
      level3Id: idMts,
      currentStatus,
      updatedStatus
    };
    return transferMonthlyTimesheet(false, 'approvedMessage', updatedAction, state, translate);
  },
  [`${APPROVE_TIMESHEET}${_FAILURE}`]: () => ({ isFetching: false, hasError: true }),
  [REJECT_TIMESHEET]: () => ({ isFetching: true, hasError: false }),
  [`${REJECT_TIMESHEET}${_SUCCESS}`]: (
    { projectId, missionId, currentStatus, updatedStatus, idMts, translate },
    state
  ) => {
    const updatedAction = {
      level1Id: projectId,
      level2Id: missionId,
      level3Id: idMts,
      currentStatus,
      updatedStatus
    };
    return transferMonthlyTimesheet(false, 'rejectedMessage', updatedAction, state, translate);
  },
  [`${REJECT_TIMESHEET}${_FAILURE}`]: () => ({ isFetching: false, hasError: true })
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  return timesheet[type] ? { ...state, ...timesheet[type](payload, state) } : state;
}
