import cookie from 'react-cookies';
import { COOKIES } from '../constants';

const { AGREEMENTS, USER_GLOBAL_AGREEMENT } = COOKIES;

export const getAgreementCookies = () => {
  const cookiesRegex = new RegExp(`${AGREEMENTS.join('|')}`);
  const userCookies = cookie.select(cookiesRegex);

  const agreementCookies = AGREEMENTS.reduce(
    (acc, key) => (userCookies[key] && JSON.parse(userCookies[key]) ? [...acc, key] : acc),
    []
  );

  return !Object.keys(userCookies).length && checkGlobalCookie() ? AGREEMENTS : agreementCookies;
};

const checkGlobalCookie = () => !!cookie.load(USER_GLOBAL_AGREEMENT);

export const checkCookie = cookieName => {
  const agreementCookies = getAgreementCookies();

  return agreementCookies.includes(cookieName);
};
