import {
  confirmEmail,
  getRegistrationStatus,
  getUser,
  login,
  quickRegister,
  refuseUserPotentialConversion,
  registerWithMissionInApi
} from '../auth.service';
import { ROLES } from '../../../constants';
import { fetchFail, fetching, fetchSuccess } from '../../utils';
import * as Sentry from '@sentry/browser';

import { userIsLogged } from '../../../utils/utils';
import { saveTokenInCookie, removeTokenFromCookie } from '../../../components/auth/utils';

const { FREELANCE } = ROLES;

export const saveTokenInBrowser = token => {
  localStorage.setItem('token', token);
  localStorage.setItem('wasLoggedInOnce', true);
  saveTokenInCookie(token);
};
const removeTokenFromBrowser = () => {
  localStorage.removeItem('token');
  removeTokenFromCookie();
};

const sentryUserScope = user => {
  Sentry.configureScope(function (scope) {
    const [name, domain] = user.email.split('@');
    const tld = domain.split('.').pop();
    scope.setUser({ id: user.id, email: `${name}@${domain.substr(0, 2)}.${user.id}.${tld}` });
  });
};

export default {
  state: {
    phoneCountries: null,
    registrationStatus: null,
    token: null,
    user: null,
    isFetching: false,
    hasError: false,
    isLoadingRegistration: false,
    isUserPotentialMutation: false
  },
  reducers: {
    loading: fetching,
    loadingFailed: fetchFail,
    loadingSuccess: fetchSuccess,
    loadingCurrentUserFailed: state => fetchFail({ ...state, user: { isLogged: false } }),
    setCurrentUser: (state, user) =>
      fetchSuccess({
        ...state,
        user: { ...user, isLogged: true },
        registrationStatus: user.registrationProgress
      }),
    loadingRegistrationStatus: state => fetching({ ...state, isLoadingRegistration: true }),
    setRegistrationStatus: (state, registrationStatus) => {
      if (state.registrationStatus) {
        //Optimization: Do not update the store if the status does not change. Why ? Because "user" is in the root context. Changing it will re-render everything
        if (JSON.stringify(state.registrationStatus) === JSON.stringify(registrationStatus)) {
          return fetchSuccess(state);
        }
      }
      return fetchSuccess({
        ...state,
        registrationStatus,
        user: {
          ...state.user,
          registrationProgress: registrationStatus
        },
        isLoadingRegistration: false
      });
    },
    loadingRegistrationStatusFailed: state =>
      fetchFail({ ...state, registrationStatus: {}, isLoadingRegistration: false }),
    setUser: (state, user) => {
      return fetchSuccess({
        ...state,
        user: { ...state.user, ...user },
        registrationStatus: user.registrationProgress
      });
    },
    setToken: (state, token) => fetchSuccess({ ...state, token }),
    setIsUserPotentialMutation: (state, isUserPotentialMutation) => ({ ...state, isUserPotentialMutation })
  },
  effects: dispatch => ({
    async loadCurrentUser(forcedUser = null) {
      if (!userIsLogged()) {
        this.loadingCurrentUserFailed();
        return;
      }
      this.loading();
      try {
        const user = forcedUser ?? (await getUser());
        this.setCurrentUser(user);
        if (user.company) dispatch.resettings.setCompany(user.company);
        sentryUserScope(user);
        if (user?.role === FREELANCE) dispatch.reprofile.loadProfile();
        dispatch.notifications.setTotalPendings(user.notification.countPending);
      } catch (e) {
        if (e.message?.includes('notAuthorized')) {
          removeTokenFromBrowser();
        }
        this.loadingCurrentUserFailed();
      }
    },
    async loadRegistrationStatus(_, rootState) {
      if (rootState.reauth.isLoadingRegistration) {
        return;
      }
      this.loadingRegistrationStatus();
      try {
        const registrationStatus = await getRegistrationStatus();
        this.setRegistrationStatus(registrationStatus);
      } catch (e) {
        this.loadingRegistrationStatusFailed();
      }
    },
    async confirmEmail(confirmToken) {
      this.loading();
      try {
        const token = await confirmEmail(confirmToken);
        saveTokenInBrowser(token);
      } catch (e) {
        this.loadingFailed();
      }
    },
    async login({ email, password }) {
      this.loading();
      try {
        const token = await login(email, password);
        this.setToken(token);
        saveTokenInBrowser(token);
      } catch (e) {
        this.setToken(null);
        this.loadingFailed();
        throw e;
      }
    },
    async registerWithMission(user) {
      this.loading();
      try {
        const { token, user: savedUser, project, mission } = await registerWithMissionInApi(user);
        this.setToken(token);
        saveTokenInBrowser(token);
        await this.loadCurrentUser(savedUser);
        return { project, mission };
      } catch (e) {
        this.loadingFailed();
        throw e;
      }
    },
    async quickRegister({ password, disclaimer }) {
      await quickRegister({ password, disclaimer: disclaimer === '1' });
      this.setIsUserPotentialMutation(false);
    },
    async refuseUserPotentialConversion() {
      this.setIsUserPotentialMutation(false);
      await refuseUserPotentialConversion();
    }
  })
};
