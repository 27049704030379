import { format as formatDate } from 'date-fns';

// non-CommonJS packages don't support I18 yet. https://date-fns.org/v1.9.0/docs/I18n
const locales = {
  en: require('date-fns/locale/en'),
  fr: require('date-fns/locale/fr'),
  nl: require('date-fns/locale/nl')
};

export const format = (date, formatStr, language) =>
  formatDate(date, formatStr, {
    locale: locales[language.substr(0, 2).toLowerCase()]
  })
  ;
