import React, { useContext } from 'react';
import { useTranslate } from '../../translate/I18n';
import { useRouter } from '../../utils/router';
import Head from 'next/head';
import { getAppUrl, getGoogleSiteVerification } from '../../utils/config';
import { cdnize } from '../../utils/pureUtils';
import BEELANCE from '../../constants/beelance';
import { UserContext } from '../auth/hook/userContext';
import { useCookie } from '../auth/hook/CookieContext';
import { COOKIES } from '../../constants';
import { NextSeo } from 'next-seo';

export const PAGES_WITH_CUSTOM_HEADER = {
  FREELANCER: 'freelancer',
  MISSION: 'mission',
  GENERAL: 'general'
};

export const DEFAULT_FACEBOOK_META_IMAGE = cdnize('/meta/beelance-facebook-meta.jpg');
export const DEFAULT_TWITTER_META_IMAGE = cdnize('/meta/beelance-twitter-meta.jpg');

const {
  social: { linkedin, twitter, facebook, instagram }
} = BEELANCE;
const { GENERAL } = PAGES_WITH_CUSTOM_HEADER;

/* eslint-disable react/prop-types */
export const buildSimpleHead =
  (name = GENERAL) =>
  ({ title, description, noindex, nofollow, link, twitterImageLink, imageLink }) => {
    const { t } = useTranslate(['metaData']);
    const publicUrl = getAppUrl();
    const googleSiteVerification = getGoogleSiteVerification();
    const router = useRouter();
    const headerTitle = title || t(`metaData:${name}:title`);
    const headerDescription = description || t(`metaData:${name}:description`);
    const headerPath = link || `${publicUrl}${router.asPath}`;
    const headerTwitterImageLink = twitterImageLink || DEFAULT_TWITTER_META_IMAGE;
    const headerImageLink = imageLink || DEFAULT_FACEBOOK_META_IMAGE;
    const user = useContext(UserContext);
    const cookie = useCookie(
      COOKIES.ANALYTICS,
      () => {},
      () => {}
    );

    const ldJson = {
      '@context': 'http://schema.org/',
      '@type': 'Organization',
      name: 'Beelance',
      url: publicUrl,
      sameAs: [linkedin.url, twitter.url, facebook.url, instagram.url],
      address: {
        '@type': 'PostalAddress',
        streetAddress: '112 Chaussée de Charleroi',
        addressRegion: 'Brussels',
        postalCode: '1060',
        addressCountry: 'BE'
      }
    };

    return (
      <>
        <NextSeo
          title={headerTitle}
          description={headerDescription}
          noindex={!!noindex}
          nofollow={!!nofollow}
          canonical={`${publicUrl}${router.asPath}`}
          openGraph={{
            type: 'website',
            url: headerPath,
            title: headerTitle,
            description: headerDescription,
            images: [
              {
                url: headerImageLink,
                width: 800,
                height: 600,
                alt: 'Beelance image'
              }
            ],
            siteName: 'Beelance'
          }}
          twitter={{
            handle: '@handle',
            site: headerPath,
            cardType: 'summary_large_image',
            images: [
              {
                url: headerTwitterImageLink,
                width: 800,
                height: 600,
                alt: 'Beelance image'
              }
            ]
          }}
        />
        <Head>
          <title>{headerTitle}</title>
          {!user?.isLoggedIn() && cookie?.accepted && (
            <script
              dangerouslySetInnerHTML={{
                __html: `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_CODE}');`
              }}
            />
          )}
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
          <link rel="manifest" href={`${publicUrl}/manifest.json`} />
          <link rel="manifest" href={`${publicUrl}/site.webmanifest`} />

          <link rel="shortcut icon" href={`${publicUrl}/favicon.ico`} />
          <link
            key="apple-touch-icon"
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${publicUrl}/apple-touch-icon.png`}
          />
          <link rel="icon" type="image/png" sizes="32x32" href={`${publicUrl}/favicon-32x32.png`} />
          <link rel="icon" type="image/png" sizes="16x16" href={`${publicUrl}/favicon-16x16.png`} />
          <link rel="mask-icon" href={`${publicUrl}/safari-pinned-tab.svg`} color="#47e1b5" />

          <meta name="msapplication-config" content={`${publicUrl}/browserconfig.xml`} />
          <meta name="theme-color" content="#ffffff" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }} />
          <meta name="google-site-verification" content={googleSiteVerification} />
        </Head>
      </>
    );
  };
