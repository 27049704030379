import { getPlatformTerritory } from '../../utils/config';

/**
 * @param {{io: *, ch: *, lu: *}} territoryObject
 * @param {string|null}defaultValue
 */
export const territorySwitch = (territoryObject, defaultValue = null) =>
  territoryObject[getPlatformTerritory()] || defaultValue;

export default territorySwitch;
