// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { getRealEnv } from './src/utils/config';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://eb7a461322c44779a07f2e436953a58d@o299816.ingest.sentry.io/1722114',
  environment: getRealEnv(),
  // release: getAppVersion(),
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'Failed to fetch',
    'Object Not Found Matching',
    'Invariant Violation',
    'ChunkLoadError'
  ],
  beforeSend(event, hint) {
    const { networkError, graphQLErrors } = hint?.originalException || {};
    event.extra = event.extra || {};
    event.extra.fromBeforeSend = true;
    if (networkError) {
      event.extra.network = {
        message: networkError?.message,
        errors: (networkError?.result?.errors || []).map(e => e.message).join('.')
      };
    }
    if (graphQLErrors) {
      event.extra.graphQLErrors = graphQLErrors;
    }

    if (event.environment === 'local') {
      return null;
    }
    return event;
  }
});
