export default Object.freeze({
  FRENCH: 'FR',
  FRENCH_CH: 'FR-CH',
  FRENCH_LU: 'FR-LU',
  ENGLISH: 'EN',
  ENGLISH_LU: 'EN-LU',
  DUTCH: 'NL',
  LOCIZE: {
    FRENCH: 'fr',
    FRENCH_CH: 'fr-CH',
    FRENCH_LU: 'fr-LU',
    ENGLISH: 'en',
    ENGLISH_LU: 'en-lu',
    DUTCH: 'nl'
  }
});
