import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Twitter = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.308 2.45a5.48 5.48 0 00-3.304 4.867l-.002.2-.267-.024A9.66 9.66 0 013.82 3.436a1 1 0 00-1.732.168c-.13.293-.313.793-.494 1.456-.596 2.193-.7 4.502.014 6.699l.096.279c.7 1.942 2.018 3.599 4.014 4.88l.197.124-.12.056a10.64 10.64 0 01-4.753.913c-1.053-.042-1.446 1.362-.526 1.873 10.04 5.578 21.486-.514 21.486-12.374l-.007-.257-.017-.235.12-.13a8.72 8.72 0 001.876-3.641c.218-.898-.793-1.586-1.548-1.054l-.33.223a9.9 9.9 0 01-1.77.906l-.194.071-.03-.027a5.48 5.48 0 00-5.575-1.005l-.22.089zm4.8 2.747a1 1 0 001.042.3l.406-.13.283-.103a6.579 6.579 0 01-.62.704 1 1 0 00-.28.9 3.5 3.5 0 01.063.646l-.003.307c-.165 8.653-7.31 13.597-14.807 11.778l-.26-.067.188-.053a12.639 12.639 0 003.444-1.641 1 1 0 00-.156-1.742C5.737 14.91 4.188 13.22 3.51 11.14l-.1-.334c-.402-1.456-.376-3.007-.055-4.524l.052-.23.094.097a11.66 11.66 0 008.527 3.391 1 1 0 00.974-1v-1a3.48 3.48 0 016.105-2.344z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Twitter;
