import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Instagram = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17 1a6 6 0 016 6v10a6 6 0 01-6 6H7a6 6 0 01-6-6V7a6 6 0 016-6zm0 2H7a4 4 0 00-4 4v10a4 4 0 004 4h10a4 4 0 004-4V7a4 4 0 00-4-4zM7.577 9.709a5 5 0 118.932 4.496 5 5 0 01-8.932-4.496zm4.906-.72a3 3 0 10-.88 5.935 3 3 0 00.88-5.935zM17.51 5.5a1 1 0 01.117 1.993L17.5 7.5a1 1 0 01-.117-1.993l.127-.007z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Instagram;
