import Facebook from '../components/atoms/icons/Facebook';
import Linkedin from '../components/atoms/icons/Linkedin';
import Twitter from '../components/atoms/icons/Twitter';
import Instagram from '../components/atoms/icons/Instagram';

export default Object.freeze({
  emails: {
    privacypolicy: 'privacypolicy@beelance.io',
    supportIO: 'support@beelance.io',
    supportCH: 'support@beelance.ch'
  },
  phones: {
    belgium: '+32 489 49 55 96'
  },
  social: {
    linkedin: {
      icon: Linkedin,
      url: 'https://www.linkedin.com/company/beelance/'
    },
    twitter: {
      icon: Twitter,
      url: 'https://twitter.com/beelance_be/'
    },
    facebook: {
      icon: Facebook,
      url: 'https://www.facebook.com/Beelance1/'
    },
    instagram: {
      icon: Instagram,
      url: 'https://www.instagram.com/beelance.io'
    }
  },
  socialCH: {
    linkedin: {
      icon: Linkedin,
      url: 'https://www.linkedin.com/company/beelance-ch/'
    },
    twitter: {
      icon: Twitter,
      url: 'https://twitter.com/Beelance_ch'
    }
  },
  routeUrl: 'https://beelance.io',
  cdnUrl: 'https://assets.beelance.io',
  trustPilot: {
    businessUnitId: '5ee888b2270f450001514f45'
  }
});
