import { prop, propOr, compose, pathOr, clone, is } from 'ramda';
import { ApolloClient, ApolloLink, split, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { toast } from 'react-toastify';
import i18n from '../translate/I18n';
import { translateVariables } from '../utils/pureUtils';
import { AUTH } from '../constants';
import { logException } from '../utils/distantLogger';
import {
  getGraphQLUrl,
  getAppVersion,
  getWebsocketUrl,
  getPlatformTerritory,
  getRatioOfNewApiCall
} from '../utils/config';
import { formatBackendErrors } from '../infrastructure/validation/error';
import { abstractLocalStorage } from '../utils/window/localStorage';
import { isInBrowser } from '../utils/reactOrNext';

const { TOKEN } = AUTH;
const cache = new InMemoryCache();
const uploadLink = split(
  () => Math.random() > getRatioOfNewApiCall(),
  createUploadLink({ uri: getGraphQLUrl(false) }),
  createUploadLink({ uri: getGraphQLUrl(true) })
);
let link = uploadLink;

if (isInBrowser()) {
  const wsLink = split(
    () => Math.random() > getRatioOfNewApiCall(),
    new WebSocketLink({
      uri: getWebsocketUrl(false),
      options: {
        lazy: true,
        reconnect: true,
        inactivityTimeout: 500,
        connectionParams: () => ({
          authorization: abstractLocalStorage.getItem(TOKEN) ? `Bearer ${abstractLocalStorage.getItem(TOKEN)}` : '',
          'X-FRONTEND-VERSION': getAppVersion(),
          'X-TERRITORY': getPlatformTerritory()
        })
      }
    }),
    new WebSocketLink({
      uri: getWebsocketUrl(true),
      options: {
        lazy: true,
        reconnect: true,
        inactivityTimeout: 500,
        connectionParams: () => ({
          authorization: abstractLocalStorage.getItem(TOKEN) ? `Bearer ${abstractLocalStorage.getItem(TOKEN)}` : '',
          'X-FRONTEND-VERSION': getAppVersion(),
          'X-TERRITORY': getPlatformTerritory()
        })
      }
    })
  );
  link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    uploadLink
  );
}

const browserAuthLink = setContext((_, { headers }) => {
  const token = abstractLocalStorage.getItem(TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'X-FRONTEND-VERSION': getAppVersion(),
      'X-TERRITORY': getPlatformTerritory()
    }
  };
});

const serverAuthLink = token =>
  setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'X-FRONTEND-VERSION': getAppVersion(),
        'X-TERRITORY': getPlatformTerritory()
      }
    };
  });

const errorLink = onError(props => {
  const { graphQLErrors, networkError, operation } = props;

  if (prop('result', networkError) === 'Potential user mutation') {
    const updateUser = async () => {
      const configuredStore = await import('../store/configureStore');
      configuredStore.dispatch.reauth.setIsUserPotentialMutation(true);
    };
    updateUser();
    return;
  }

  if (networkError) {
    const message = networkError?.message || 'Network error unknown';
    logException(message, {
      operationName: operation?.operationName,
      result: networkError.result,
      networkError: (networkError?.result?.errors || []).map(e => e.message).join('.'),
      graphQLErrors
    });
  }

  if (operation.getContext()?.byPassErrorHandling) {
    return;
  }

  if (graphQLErrors) {
    graphQLErrors.map(graphQLError => {
      const { key: formErrorsKey, variables: formErrorsVariables } = compose(
        propOr({}, 0),
        pathOr([], ['data', 'fields'])
      )(graphQLError);
      const { key: otherErrorsKey, variables: otherErrorsVariables } = propOr({}, 'data', graphQLError);

      const error = formErrorsKey || otherErrorsKey;
      const variables = formErrorsKey ? formErrorsVariables : otherErrorsVariables;

      const errorMsg = error
        ? i18n.t(`validation:${error}`, translateVariables(variables, i18n.t))
        : prop('message', graphQLError);

      const errorMsgDisplay = errorMsg || prop('message', graphQLError);
      toast.error(errorMsgDisplay);

      return null;
    });
  }
});

const _getSSRApolloClient = token => {
  return new ApolloClient({
    cache,
    link: ApolloLink.from([serverAuthLink(token).concat(new HttpLink({ uri: getGraphQLUrl() }))]),
    ssrMode: true
  });
};

const _getBrowserApolloClient = () => {
  return new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, browserAuthLink.concat(link)]),
    ssrMode: false
  });
};

const client = isInBrowser ? _getBrowserApolloClient() : _getSSRApolloClient();

const getClient = token => {
  return token ? _getSSRApolloClient(token) : client;
};

export const removeTypenames = obj => {
  if (!is(Object, obj)) return obj;
  const objClone = clone(obj);

  Object.keys(objClone).forEach(key => {
    if (key === '__typename') {
      delete objClone[key];
    } else if (is(Object, objClone[key])) {
      objClone[key] = removeTypenames(objClone[key]);
    }
  });

  return objClone;
};

/**
 * @param {string} mutationQuery
 * @param {Object} variables
 * @param {boolean} byPassErrorHandling
 * @return {Object}
 */
const mutate = async (mutationQuery, variables = {}, byPassErrorHandling = true) => {
  try {
    const response = await client.mutate({
      mutation: mutationQuery,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        byPassErrorHandling
      }
    });
    return removeTypenames(response);
  } catch (e) {
    throw formatBackendErrors(e);
  }
};

/**
 * @param {string} mutationQuery
 * @param {Object} variables
 * @param {boolean} byPassErrorHandling
 * @return {Object}
 */
const query = async (query, variables = {}, byPassErrorHandling = true) => {
  try {
    const response = await client.query({
      query,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        byPassErrorHandling
      }
    });
    return removeTypenames(response);
  } catch (e) {
    throw formatBackendErrors(e);
  }
};

export { client, getClient, mutate, query };
