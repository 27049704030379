import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Clock = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm0 2c.552 0 1 .492 1 1.1v5.92l3.447 1.896c.494.272.694.932.447 1.476-.247.543-.847.763-1.341.492l-4-2.2c-.339-.187-.553-.568-.553-.984V5.1c0-.608.448-1.1 1-1.1z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Clock;
