import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    color: ${(props) => props.theme.colors.dark};
    font-weight: 400;
  }
  a {
    font-weight: 700;
  }
`;

export default GlobalStyle;
