import { STATUS } from '../constants';

import House from '../components/atoms/icons/House';
import Users from '../components/atoms/icons/Users';
import Table from '../components/atoms/icons/Table';

const {
  MISSION_LABEL: { DRAFT, PUBLISHED, PAST },
  APPLICATION: { ONGOING }
} = STATUS;

const main = {
  newProject: {
    to: '/newProject',
    label: 'common:createProject'
  },
  application: {
    to: '/application/:applicationId'
  },
  projects: {
    icon: Table,
    to: '/home',
    label: 'common:dashboard',
    activePaths: ['/project/']
  },
  project: {
    to: '/project/:projectId'
  },
  newMission: {
    to: '/project/:projectId/newMission',
    label: 'mission:createMission'
  },
  editMission: {
    to: '/project/:projectId/edit-mission/:missionId'
  },
  registerWithMission: {
    to: '/post-a-mission'
  },
  companyProfile: {
    icon: House,
    to: '/company/:companyId',
    label: 'common:companyProfile'
  }
};

const dashboard = {
  all: {
    to: '/project/:projectId/all-missions',
    label: 'all',
    prop: 'all'
  },
  [PUBLISHED]: {
    to: '/mission/:missionId',
    label: 'published',
    prop: PUBLISHED
  },
  [ONGOING]: {
    to: '/project/:projectId/ongoing-missions',
    label: 'ongoing',
    prop: ONGOING
  },
  [DRAFT]: {
    to: '/project/:projectId/draft-missions',
    label: 'draft',
    prop: DRAFT
  },
  [PAST]: {
    to: '/project/:projectId/past-missions',
    label: 'past',
    prop: PAST
  }
};

const administration = {
  applications: {
    icon: Users,
    to: '/applications',
    label: 'common:applications'
  }
};

const settings = {
  subscription: {
    icon: 'calendar',
    to: '/settings/subscription',
    label: 'common:subscriptionPlan'
  },
  teams: {
    icon: 'users',
    to: '/teams',
    label: 'common:team'
  }
};

export default {
  ...main,
  administration,
  dashboard,
  settings
};
