import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import theme from '../theme/index';
import GlobalStyle from '../GlobalStyle';
import '../index.scss';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from '../../next-i18next.config.js';
import { ToastContainer, Slide } from 'react-toastify';
import ToasterCloseButton from '../components/elements/ToasterCloseButton';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-image-crop/lib/ReactCrop.scss';
import NextjsErrorBoundary from '../components/NextjsErrorBoundary';
import { UserProvider } from '../components/auth/hook/userContext';
import { useTranslate } from '../translate/I18n';
import cookie from 'react-cookies';
import { useRouter } from '../utils/router';
import { buildSimpleHead } from '../components/layout/heading';
import { Provider } from 'react-redux';
import { useStore } from '../store/configureStore';
import LayoutComponent from '../components/layout/LayoutComponent';
import { CookieProvider } from '../components/auth/hook/CookieContext';
import { isInBrowser } from '../utils/reactOrNext';
import { isInE2eMode } from '../utils/e2eUtils';
import { storageListener } from '../utils/utils';
import { reloadIfNotLastFrontendVersion } from '../utils/reload';
import { useInterval } from '../hooks/useInterval';

const _1heureInMilliseconds = 1000 * 60 * 60;
const _30minutesInMilliseconds = 1000 * 60 * 30;

function MyApp({ Component, pageProps }) {
  const store = useStore();
  const { i18n } = useTranslate();
  const router = useRouter();

  // expose store when run in Cypress
  if (isInE2eMode()) {
    window.__store__ = store;
  }

  // When user already has a localStorage language from the React Beelance version
  if (isInBrowser() && i18n.language !== undefined) {
    const storedLanguage = window.localStorage.getItem('storedLanguage');
    if (!cookie.load('NEXT_LOCALE') && storedLanguage) {
      // Used to avoid transforming "fr-CH" into "fr-ch", which is not supported
      const forcedLanguage = storedLanguage.includes('-') ? storedLanguage : storedLanguage.toLowerCase();
      cookie.save('NEXT_LOCALE', forcedLanguage, { path: '/' });
      if (i18n.language !== forcedLanguage) {
        router.push(router.route, router.asPath, { locale: forcedLanguage });
      }
    }
  }

  useEffect(() => {
    storageListener();
  }, []);

  useInterval({
    callback: reloadIfNotLastFrontendVersion,
    timeToTriggerCallBackInMilliSecond: _1heureInMilliseconds,
    onlyWhenUserActive: true,
    timeToConsiderUserInactiveInMilliSecond: _30minutesInMilliseconds
  });

  const DynamicHead = Component.getHead ? Component.getHead() : buildSimpleHead('general');
  const layoutProps = Component.getLayoutProps ? Component.getLayoutProps() : {};

  return (
    <NextjsErrorBoundary>
      <Provider store={store}>
        <CookieProvider active>
          <UserProvider>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <ToastContainer
                position="top-center"
                autoClose={9000}
                transition={Slide}
                closeButton={ToasterCloseButton}
                newestOnTop
                icon={false}
              />
              {/* <Script src="https://www.eventbrite.fr/static/widgets/eb_widgets.js" strategy="beforeInteractive" /> */}
              <DynamicHead {...pageProps} />
              <LayoutComponent component={Component} layoutProps={layoutProps}>
                <Component {...pageProps} />
              </LayoutComponent>
            </ThemeProvider>
          </UserProvider>
        </CookieProvider>
      </Provider>
    </NextjsErrorBoundary>
  );
}

MyApp.propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object
};

export default appWithTranslation(MyApp, nextI18NextConfig);
