import React, { Component } from 'react';
import { node } from 'prop-types';
import * as Sentry from '@sentry/nextjs';

export default class NextjsErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtra('error', error);
      scope.setExtra('errorInfo', errorInfo);
      scope.setExtra('whitePage', 'Yes');
      Sentry.captureException(error);
    });
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <>
          <h1>Something went wrong. But don&apos;t worry, we are taking care of it!</h1>
          <div>
            <ul>
              <li>Try to refresh the page</li>
              <li>
                If the error persists, feel free to <a href="mailto:support@beelance.io">contact our support team</a>.
                We will be happy to help
              </li>
            </ul>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}

NextjsErrorBoundary.propTypes = {
  children: node
};
