import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Table = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5 22a3 3 0 01-3-3V5a3 3 0 013-3h14a3 3 0 013 3v14a3 3 0 01-3 3H5zm2.999-12H4v9a1 1 0 001 1h3l-.001-10zM20 10H9.999L10 20h9a1 1 0 001-1v-9zM7.999 4H5a1 1 0 00-1 1v3h3.999V4zM19 4H9.999v4H20V5a1 1 0 00-1-1z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Table;
