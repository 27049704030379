import { query, mutate } from '../../apollo/client';

export async function fetchTimeSheets(variables) {
  const { default: getTimeSheets } = await import('../../graphql/timesheet/getTimeSheets');
  return query(getTimeSheets, variables);
}

export async function submitTimesheet(variables) {
  const { default: submitTimesheetQuery } = await import('../../graphql/timesheet/submitTimeSheet');
  return mutate(submitTimesheetQuery, variables, false);
}

export async function updateDailyTimeSheet(variables) {
  const { default: saveHours } = await import('../../graphql/timesheet/saveHours');
  return mutate(saveHours, variables, false);
}

export async function approveTimeSheet(variables) {
  const { default: validateTimeSheet } = await import('../../graphql/timesheet/validateTimeSheet');
  return mutate(validateTimeSheet, variables, false);
}

export async function rejectTimeSheet(variables) {
  const { default: rejectTimeSheetQuery } = await import('../../graphql/timesheet/rejectTimeSheet');
  return mutate(rejectTimeSheetQuery, variables, false);
}
