import notifications from './notifications/models/notifications';
import messages from './chat/models/messages';
import reauth from './auth/models/reauth';
import remissions from './missions/models/remissions';
import reprofile from './profile/models/reprofile';
import reprojects from './projects/models/reprojects';
import resettings from './settings/models/resettings';
import resubscription from './subscription/models/resubscription';
import reapplications from './applications/models/reapplications';

export const models = {
  notifications,
  messages,
  reapplications,
  reauth,
  reprofile,
  reprojects,
  remissions,
  resettings,
  resubscription
};
