/* eslint max-lines: 0 */
import { useState } from 'react';
import {
  is,
  curry,
  sortBy,
  toLower,
  compose,
  prop as ramdaProp,
  omit,
  propOr,
  isEmpty,
  isNil,
  complement,
  find,
  values as ramdaValues
} from 'ramda';
import { toast } from 'react-toastify';
import { differenceInCalendarDays, differenceInSeconds } from 'date-fns';
import { format } from './date-fns';

import MISSION from '../domain/mission/constant';
import BEELANCE from '../constants/beelance';
import { defaultSkillValue } from '../components/elements/form/constants';

import i18n from '../translate/I18n';
import { logMessage } from './distantLogger';
import BeelanceDate from './BeelanceDate';
import { abstractLocalStorage } from './window/localStorage';
import { isInBrowser } from './reactOrNext';

const {
  WORKTIME: { PARTTIME },
  FULL_REMOTE,
  PARTIAL_REMOTE
} = MISSION;

const { cdnUrl } = BEELANCE;

export function toastNotification(type, prop, key, translate) {
  const message = translate(`${prop}:${key}`);
  if (!message) {
    logMessage(`No translation key for: ${i18n.language} - ${prop}:${key}`, 'warning');
    return;
  }
  toast[type](message);
}

export function storageListener() {
  if (isInBrowser()) {
    window.addEventListener('storage', listenLogoutToken);
  }
}

export function listenLogoutToken(event) {
  if (event.key === 'token' && event.oldValue !== event.newValue) {
    window.location.replace('/');
  }
}

export function nullFunction(..._args) {
  return null;
}

export function trueFunction(..._args) {
  return true;
}

export function emptyFunction(..._args) { }

export function createEmptyObject(..._args) {
  return {};
}

/**
 * @param {number} olderYearWanted
 */
export const getYears = (olderYearWanted) => {
  const yearsArray = [];
  let year = new Date().getFullYear();
  const minYear = olderYearWanted || 1960;
  while (year > minYear) {
    yearsArray.push(year--);
  }
  yearsArray.push(minYear);
  return yearsArray;
};

export const getMonths = () => [
  { key: 'january', value: 0 },
  { key: 'february', value: 1 },
  { key: 'march', value: 2 },
  { key: 'april', value: 3 },
  { key: 'may', value: 4 },
  { key: 'june', value: 5 },
  { key: 'july', value: 6 },
  { key: 'august', value: 7 },
  { key: 'september', value: 8 },
  { key: 'october', value: 9 },
  { key: 'november', value: 10 },
  { key: 'december', value: 11 }
];
export const getDisplayWorkTime = (label, t) => (label ? t(`mission:workTime_${label}`) : '-');

/**
 * @deprecated use pureUtils
 */
export const roundNbToNDecimals = decimalsAccepted => nb => {
  const decimals = Number(decimalsAccepted);
  const number = Number(nb);
  if (isNaN(number) || isNaN(decimals)) return 0;
  const factor = Math.pow(10, decimals);
  return Math.round(number * factor) / factor;
};

export const formatMessageDate = (timestamp, t, language = null) => {
  const diff = differenceInSeconds(new Date().getTime(), parseInt(timestamp));
  if (diff < 60) return t('common:seconds_ago', { value: diff });
  else if (diff < 60 * 60) return t('common:mins_ago', { value: Math.round(diff / 60) });
  else if (diff < 60 * 60 * 24) return t('common:hours_ago', { value: Math.round(diff / 60 / 60) });
  else if (diff < 60 * 60 * 24 * 7) return t('common:days_ago', { value: Math.round(diff / 60 / 60 / 24) });
  else return format(parseInt(timestamp), 'DD MMM YY', language);
};
export const formatDayString = (timestamp, t, language = null) => {
  const today = new Date();
  const diff = differenceInCalendarDays(today, new Date(Number(timestamp)));
  if (diff === 0) return t('common:today');
  else if (diff === 1) return t('common:yesterday');
  else return format(parseInt(timestamp), 'DD MMMM YYYY', language);
};

export const isTimestamp = timestamp => {
  if (is(Number, timestamp)) return new Date(timestamp).getTime() > 0;

  const test = new Date(timestamp).getTime();

  if (isNaN(test)) return isTimestamp(parseInt(timestamp));

  return test > 0;
};

export const capitalize = str => str.replace(/^\w/, c => c.toUpperCase());

export const replaceSubString = curry((toRepl, repl, str) => {
  const reg = new RegExp(toRepl);
  return str.replace(reg, repl.toString());
});

export const replaceWhiteSpaceWithEmptyString = replaceSubString(/\s/g, '');

export const sortByPropCaseInsensitive = prop => sortBy(compose(toLower, ramdaProp(prop)));

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = value => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      return value;
    }
  };

  return [storedValue, setValue];
};

export const convertNbHourMaxByWeekToDaysPerWeek = nbHourMaxByWeek => nbHourMaxByWeek / 8;
export const convertDaysPerWeekToNbHourMaxByWeek = nbHourMaxByWeek => nbHourMaxByWeek * 8;

export const formatStartAndEndDatesForBE = ({ isAsap, isUndefined, startDate, endDate }) => {
  const dates = {};
  if (!isAsap) {
    dates.startDate = startDate;
  }
  if (!isUndefined) {
    dates.endDate = endDate;
  }
  return dates;
};

export const formatWorkingStyleForBE = ({ workingStyle }) => {
  if (workingStyle) {
    return { workingStyle };
  }
  return {};
};

export const formatLocationForBE = ({ workingStyle, location }) => {
  if (workingStyle !== FULL_REMOTE) {
    return { location };
  }
  return { location: null };
};

export const formatMinimumAmountOfDaysOnSite = ({ workingStyle, minimumAmountOfDaysOnSite }) => {
  if (workingStyle !== PARTIAL_REMOTE) {
    return { minimumAmountOfDaysOnSite: null };
  }

  if (workingStyle === PARTIAL_REMOTE && !minimumAmountOfDaysOnSite) {
    return { minimumAmountOfDaysOnSite: null };
  }

  return { minimumAmountOfDaysOnSite };
};

export const formatWorkingTimeForBE = ({ workTime, daysPerWeek }) => {
  if (workTime) {
    return {
      weeklyPlanning: {
        workTime,
        nbHourMaxByWeek: convertDaysPerWeekToNbHourMaxByWeek(workTime === PARTTIME ? daysPerWeek : 5),
        monday: 8,
        tuesday: 8,
        wednesday: 8,
        thursday: 8,
        friday: 8,
        nbHourMaxByDay: 8
      }
    };
  }
  return {};
};

export const formatExtraTimeForBE = ({
  extraTime,
  mondayFridayDay = 0,
  mondayFridayNight = 0,
  saturdayDay = 0,
  saturdayNight = 0,
  sundayOrHolidayDay = 0,
  sundayOrHolidayNight = 0
}) => {
  if (extraTime) {
    return {
      extraTime: {
        mondayFridayDay,
        mondayFridayNight,
        saturdayDay,
        saturdayNight,
        sundayOrHolidayDay,
        sundayOrHolidayNight
      }
    };
  }
  return {};
};

export const formatMissionValuesForBE = ({
  extraTime,
  mondayFridayDay,
  mondayFridayNight,
  saturdayDay,
  saturdayNight,
  sundayOrHolidayDay,
  sundayOrHolidayNight,
  workTime,
  daysPerWeek,
  startDate,
  endDate,
  isAsap,
  isUndefined,
  location,
  _matching,
  workingStyle,
  ...otherMissionValues
}) =>
({
  ...formatStartAndEndDatesForBE({ isAsap, isUndefined, startDate, endDate }),
  ...formatWorkingStyleForBE({ workingStyle }),
  ...formatLocationForBE({ workingStyle, location }),
  ...formatWorkingTimeForBE({ workTime, daysPerWeek }),
  ...formatExtraTimeForBE({
    extraTime,
    mondayFridayDay,
    mondayFridayNight,
    saturdayDay,
    saturdayNight,
    sundayOrHolidayDay,
    sundayOrHolidayNight
  }),
  ...otherMissionValues,
  isAsap,
  isUndefined
});

export const formatMissionContractValuesForBE = ({
  extraTime,
  mondayFridayDay,
  mondayFridayNight,
  saturdayDay,
  saturdayNight,
  sundayOrHolidayDay,
  sundayOrHolidayNight,
  workTime,
  daysPerWeek,
  startDate,
  endDate,
  isAsap,
  isUndefined,
  location,
  workingStyle,
  noticePeriod,
  paymentCondition,
  hourlyRate,
  fixedPrice,
  minimumAmountOfDaysOnSite,
  ...otherMissionContractValues
}) =>
  compose(
    omit(['skills'])
  )({
    ...formatStartAndEndDatesForBE({ isAsap, isUndefined, startDate, endDate }),
    ...formatWorkingStyleForBE({ workingStyle }),
    ...formatLocationForBE({ workingStyle, location }),
    ...formatMinimumAmountOfDaysOnSite({ workingStyle, minimumAmountOfDaysOnSite }),
    ...formatWorkingTimeForBE({ workTime, daysPerWeek }),
    ...formatExtraTimeForBE({
      extraTime,
      mondayFridayDay: mondayFridayDay ? parseInt(mondayFridayDay) : mondayFridayDay,
      mondayFridayNight: mondayFridayNight ? parseInt(mondayFridayNight) : mondayFridayNight,
      saturdayDay: saturdayDay ? parseInt(saturdayDay) : saturdayDay,
      saturdayNight: saturdayNight ? parseInt(saturdayNight) : saturdayNight,
      sundayOrHolidayDay: sundayOrHolidayDay ? parseInt(sundayOrHolidayDay) : sundayOrHolidayDay,
      sundayOrHolidayNight: sundayOrHolidayNight ? parseInt(sundayOrHolidayNight) : sundayOrHolidayNight
    }),
    noticePeriod: noticePeriod ? parseInt(noticePeriod) : noticePeriod,
    paymentCondition: paymentCondition ? parseInt(paymentCondition) : paymentCondition,
    hourlyRate: hourlyRate ? parseFloat(hourlyRate) : hourlyRate,
    fixedPrice: fixedPrice ? parseFloat(fixedPrice) : fixedPrice,
    ...otherMissionContractValues,
    isAsap,
    isUndefined
  });

const formatWeeklyPlanningForFE = ({ workTime = '', nbHourMaxByWeek }) => ({
  workTime,
  daysPerWeek: workTime === PARTTIME && nbHourMaxByWeek ? convertNbHourMaxByWeekToDaysPerWeek(nbHourMaxByWeek) : ''
});

const formatExtratimeForFE = extraTime => ({
  extraTime: !!extraTime || false,
  mondayFridayDay: propOr('', 'mondayFridayDay', extraTime),
  mondayFridayNight: propOr('', 'mondayFridayNight', extraTime),
  saturdayDay: propOr('', 'saturdayDay', extraTime),
  saturdayNight: propOr('', 'saturdayNight', extraTime),
  sundayOrHolidayDay: propOr('', 'sundayOrHolidayDay', extraTime),
  sundayOrHolidayNight: propOr('', 'sundayOrHolidayNight', extraTime)
});

export const formatMissionValuesForFE = values => {
  const {
    weeklyPlanning,
    extraTime,
    startDate,
    endDate,
    workingStyle,
    location,
    minimumAmountOfDaysOnSite,
    skills = {
      hard: [],
      soft: [],
      language: []
    },
    attachments,
    ...otherMissionValues
  } = values;

  skills.hard = isEmpty(skills.hard) ? [{ ...defaultSkillValue }] : skills.hard;

  return {
    startDate: startDate || '',
    endDate: endDate || '',
    minimumAmountOfDaysOnSite: minimumAmountOfDaysOnSite || 1,
    ...formatWeeklyPlanningForFE(weeklyPlanning || {}),
    ...formatExtratimeForFE(extraTime),
    ...formatLocationForBE({ workingStyle, location }),
    workingStyle,
    skills,
    ...(attachments && { attachments: attachments.map(file => ({ file })) }),
    ...otherMissionValues
  };
};

export const formatDate = (date, language = null) => {
  return format(date, 'D MMM YYYY', language);
};

const formatISODate = (isoString, language = null) => {
  try {
    return BeelanceDate.fromISOString(isoString).format('D MMM YYYY', language);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return '-';
  }
};

export const getMissionDate = (date, alt, language) => (isNil(date) ? alt : formatISODate(date, language));

/**
 *
 * @param {string} path
 * @param {object} params
 */
export const pathWithParams = (path, params) => path.replace(/:(\w+)/g, (_, k) => params[k]);

export const historyPush = curry((history, path, _) => history.push(path));

export const historyPushWithParams = curry((history, path, params) => history.push(pathWithParams(path, params)));

export const getTimestampZoneDifference = timestamp => -new Date(timestamp).getTimezoneOffset() * 60000;

const addOrRemoveTimestampDifference = curry((isAddition, initialTime) => {
  if (!isTimestamp(initialTime)) return 0;

  const timestamp = Number(initialTime);
  const difference = getTimestampZoneDifference(timestamp);
  return isAddition ? timestamp + difference : timestamp - difference;
});

export const syncWithServerTime = addOrRemoveTimestampDifference(true);
export const syncWithLocalTime = addOrRemoveTimestampDifference(false);

export const addField = (fields, defaultValue) => () => fields.push(defaultValue);
export const removeField = (fields, index) => () => fields.remove(index);

const isNotNil = complement(isNil);
export const findIsNotNilInArrayOfObjects = compose(find(isNotNil), ramdaValues);

export const userIsLogged = () => {
  return !!abstractLocalStorage.getItem('token');
};

export const stripHtml = html =>
  (html || '')
    .replace(/(<([^>]+)>)/gi, ' ')
    .replace(/ +(?= )/g, '')
    .replace(/&nbsp;/g, '')
    .replace(/&amp;/g, '&')
    .trim();

export const truncateText = (text, maxLength) => {
  if (typeof text !== 'string') {
    return '';
  }
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

/**
 * @deprecated use pureUtils
 */
export const cdnize = path => {
  return `${cdnUrl}${path}`;
};

/**
 * @deprecated use pureUtils
 */
export const getFormattedFileSize = bytes => {
  if (isNaN(bytes)) return '';

  const bytesPerKiloByte = 1024;
  const bytesPerMegaByte = 1048576;
  const roundToOneDecimal = roundNbToNDecimals(1);

  if (bytes < bytesPerKiloByte) return `${roundToOneDecimal(bytes)}B`;
  if (bytes < bytesPerMegaByte) return `${roundToOneDecimal(bytes / bytesPerKiloByte)}KB`;
  return `${roundToOneDecimal(bytes / bytesPerMegaByte)}MB`;
};

export const splitText = function (original, splitter) {
  if (!original) return '';
  if (!splitter) {
    return original;
  }
  splitter = splitter.replace(/[*/+?^${}()|[\]\\]/g, '\\$&');
  return original.split(new RegExp(`(${splitter})`, 'gi'));
};

export const isMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
