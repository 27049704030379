import pkg from '../../package.json';
import { getFrontendVersion } from '../domain/appMetadata/appMetadataRepository';
import { getRealEnv } from './config';
import { isInBrowser } from './reactOrNext';

export const reloadIfNotLastFrontendVersion = async () => {
    return;
    // eslint-disable-next-line no-unreachable
    const lastestFrontendVersion = await getFrontendVersion();
    const currentFrontendVersion = pkg.version;

    if (!lastestFrontendVersion || !currentFrontendVersion) return;

    if (
        currentFrontendVersion !== lastestFrontendVersion &&
        getRealEnv() === 'production' &&
        isInBrowser()
    ) window.location.reload();
};