import React from 'react';
import Cross from '../atoms/icons/Cross';
import styled from 'styled-components';
import Button from '../atoms/Button';

const StyledCloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: theme.space[1],
  padding: `0 0 0 ${theme.space[1]}px`
}));

const ToasterCloseButton = ({ closeToast }) => (
  <StyledCloseButton styleType="transparent" onClick={closeToast}>
    <Cross color="grey" fontSize="14px" />
  </StyledCloseButton>
);

ToasterCloseButton.propTypes = {
  closeToast: () => null
};

export default ToasterCloseButton;
