import { mutate, query } from '../../apollo/client';
import { abstractLocalStorage } from '../../utils/window/localStorage';

export async function getUser() {
  const { default: getUserQuery } = await import('../../graphql/user/getUser');

  const response = await query(getUserQuery);
  return response.data.getUser;
}

export async function getRegistrationStatus() {
  const { default: getRegistrationProgressFromUser } = await import(
    '../../graphql/auth/getRegistrationProgressFromUser'
  );
  const response = await query(getRegistrationProgressFromUser, null, false);
  return response.data.getUser.registrationProgress;
}

export async function resendEmail(variables) {
  const { default: resendEmailQuery } = await import('../../graphql/auth/resendEmail');
  const response = mutate(resendEmailQuery, variables);
  return response.data.resendEmail;
}

export async function updateUser(user) {
  const { default: updateUserQuery } = await import('../../graphql/user/updateUserAccount');

  const response = await mutate(updateUserQuery, { userInfo: user });
  return response.data.updateUserAccount;
}

export async function confirmEmail(token) {
  const { default: confirmEmailQuery } = await import('../../graphql/auth/confirmEmail');

  const response = await mutate(confirmEmailQuery, { token }, false);
  return response.data.confirmEmail.token;
}

/**
 * @param {string} email
 * @param {string} password
 * @returns {Promise<string>}
 */
export async function login(email, password) {
  const { default: loginQuery } = await import('../../graphql/auth/login');

  const response = await mutate(loginQuery, { login: { email, password } });
  return response.data.login.token;
}

export async function register(user) {
  const { default: registerQuery } = await import('../../graphql/auth/register');

  const response = await mutate(registerQuery, { user });

  return {
    token: response.data.register.token,
    user: response.data.register.user
  };
}

export async function registerWithMissionInApi({ user, mission }) {
  const { default: registerWithMissionQuery } = await import('../../graphql/auth/registerWithMission');

  const response = await mutate(registerWithMissionQuery, { user, mission });
  return response.data.registerWithMission;
}

export async function quickRegister({ password, disclaimer }) {
  const { default: convertUserPotential } = await import('../../graphql/user/convertUserPotential');
  await mutate(convertUserPotential, { password, disclaimer }, false);
}

export async function refuseUserPotentialConversion() {
  const { default: refuseUserPotentialConversionQuery } = await import(
    '../../graphql/user/refuseUserPotentialConversion'
  );

  await mutate(refuseUserPotentialConversionQuery, null, false);
}
