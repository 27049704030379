import { fetchFail, fetching, fetchSuccess } from '../../utils';
import { SUBSCRIPTIONS } from '../../../constants';
import {
  getActiveSubscription,
  getSubscriptionPlans,
  createNewSubscription,
  cancelSubscription
} from '../subscription.services';

const {
  SUBSCRIPTION_STATUS: { PENDING_ARCHIVED }
} = SUBSCRIPTIONS;

export default {
  state: {
    current: null,
    plans: null,
    options: null,
    payments: null,
    isFetching: false,
    isSubscribing: false,
    hasError: false,
    isLoadingPlans: false,
    isLoadingActiveSubscription: false
  },
  reducers: {
    loading: fetching,
    loadingFailed: fetchFail,
    loadingSuccess: fetchSuccess,

    loadingPlans: state => fetching({ ...state, isLoadingPlans: true }),
    setPlans: (state, plans) => fetchSuccess({ ...state, plans, isLoadingPlans: false }),
    loadingPlansFailed: state => fetchFail({ ...state, plans: [], isLoadingPlans: false }),

    loadingActiveSubscription: state => fetching({ ...state, isLoadingActiveSubscription: true }),
    setActiveSubscription: (state, current) => fetchSuccess({ ...state, current, isLoadingActiveSubscription: false }),
    subscriptionCancelled: state => ({
      ...state,
      current: { ...state.current, status: PENDING_ARCHIVED }
    }),
    loadingActiveSubscriptionFailed: state => fetchFail({ ...state, current: {}, isLoadingActiveSubscription: false })
  },
  effects: () => ({
    async loadPlans(_, rootState) {
      if (rootState.resubscription.isLoadingPlans || rootState.resubscription.plans?.length > 0) {
        return;
      }
      this.loadingPlans();
      try {
        const subscriptionPlans = await getSubscriptionPlans();
        this.setPlans(subscriptionPlans);
      } catch (e) {
        this.loadingPlansFailed();
      }
    },
    async createSubscription({ plan, option }) {
      const activeSubscription = await createNewSubscription({ plan, option });
      this.setActiveSubscription(activeSubscription);
    },
    async cancelSubscription() {
      await cancelSubscription();
      this.subscriptionCancelled();
    },
    async loadActiveSubscription(_, rootState) {
      if (rootState.resubscription.isLoadingActiveSubscription || rootState.resubscription.current) {
        return;
      }
      this.loadingActiveSubscription();
      try {
        const activeSubscription = await getActiveSubscription();
        this.setActiveSubscription(activeSubscription);
      } catch (e) {
        this.loadingActiveSubscriptionFailed();
      }
    }
  })
};
