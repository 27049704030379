import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Loading = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 17.6a1 1 0 011 1V23a1 1 0 01-2 0v-4.4a1 1 0 011-1zm3.957-1.643a1 1 0 011.414 0l3.113 3.113a1 1 0 01-1.414 1.414l-3.113-3.113a1 1 0 010-1.414zm-9.328 0a1 1 0 111.414 1.414L4.93 20.484a1 1 0 11-1.414-1.414zM5.4 11a1 1 0 010 2H1a1 1 0 010-2zM23 11a1 1 0 010 2h-4.4a1 1 0 010-2zM3.516 3.516a1 1 0 011.414 0l3.113 3.113A1 1 0 016.63 8.043L3.516 4.93a1 1 0 010-1.414zm15.554 0a1 1 0 011.414 1.414l-3.113 3.113a1 1 0 01-1.414-1.414zM12 0a1 1 0 011 1v4.4a1 1 0 01-2 0V1a1 1 0 011-1z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Loading;
