import { fetchFail, fetching, fetchSuccess } from '../../utils';
import { getPendingMessagesCount } from '../chat.service';

export default {
  state: {
    pendingMessagesCount: 0,
    isLoaded: false,
    isLoading: false,
    isFetching: false,
    hasError: false
  },
  reducers: {
    loading: fetching,
    loadingFailed: fetchFail,
    setloadingPendingMessagesCount: state => fetching({ ...state, list: [], isLoading: true }),
    setPendingMessagesCount: (state, count) =>
      fetchSuccess({ ...state, pendingMessagesCount: count, isLoaded: true, isLoading: false }),
    loadingPendingMessagesCountFailed: state => fetchFail({ ...state, list: [], isLoading: false })
  },
  effects: () => ({
    async loadPendingMessagesCount(_, rootState) {
      if (rootState.messages.isFetching || rootState.messages.isLoaded) {
        return;
      }
      this.setloadingPendingMessagesCount();
      try {
        const pendingMessagesCount = await getPendingMessagesCount();
        this.setPendingMessagesCount(pendingMessagesCount);
      } catch (e) {
        this.loadingPendingMessagesCountFailed();
      }
    },
    async reloadPendingMessagesCount() {
      this.setPendingMessagesCount(await getPendingMessagesCount());
    }
  })
};
