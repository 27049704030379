import React from 'react';
import dynamic from 'next/dynamic';
import { useUserContext } from '../auth/hook/userContext';
import { useRouter } from '../../utils/router';
import { ForbiddenAddress } from './ForbiddenAddress';
import { isInBrowser } from '../../utils/reactOrNext';
import { commonRoutes } from '../../routes';

const AppLoader = dynamic(() => import('../elements/AppLoader'));

// eslint-disable-next-line react/prop-types
const NoLayout = ({ children }) => <>{children}</>;

// eslint-disable-next-line react/prop-types
const LayoutComponent = ({ component, children, layoutProps }) => {
  const user = useUserContext();
  const router = useRouter();
  try {
    const Layout = component.getLayout ? component.getLayout(user) : NoLayout;
    return <Layout {...layoutProps}>{children}</Layout>;
  } catch (e) {
    if (e instanceof ForbiddenAddress) {
      //router is only available in browser
      if (isInBrowser()) {
        router.push(`${e.redirectAddress}${e.hasReturnUrl ? `?overrideGoBack=${commonRoutes.home.to}` : ''}`);
      }
      return <AppLoader />;
    } else {
      throw e;
    }
  }
};

export default LayoutComponent;
