import { append, compose, reject } from 'ramda';
import { fetchFail, fetching, fetchSuccess } from '../../utils';
import { sortByPropCaseInsensitive } from '../../../utils/utils';
import { getProjectCategories, getProject, getProjects, addProject, deleteProject } from '../projects.service';

export default {
  state: {
    list: null,
    current: null,
    categories: null,
    isFetching: false,
    hasError: false,
    loadingSearch: false,
    loadingMissionId: null,
    loadingMatchingRoles: false,
    loadingProjectId: ''
  },
  reducers: {
    loading: fetching,
    loadingFailed: fetchFail,
    loadingSuccess: fetchSuccess,
    setProjects: (state, projects) => fetchSuccess({ ...state, list: projects }),
    loadingCurrentProject: (state, projectId) => fetching({ ...state, current: null, loadingProjectId: projectId }),
    loadingCurrentProjectFailed: (state) => fetchFail({ ...state, current: {}, loadingProjectId: '' }),
    setCurrentProject: (state, { project }) => fetchSuccess({ ...state, current: project, loadingProjectId: '' }),
    setCategories: (state, categories) => ({ ...state, categories }),
    'remissions/deleteMission': (state, { missionId, projectId }) => ({
      ...state,
      list: state.list.map((project) => {
        if (project.id !== projectId) return project;

        return {
          ...project,
          missions: project.missions.filter((mission) => mission.id !== missionId)
        };
      })
    }),
    addMission: (state, { projectId, mission }) => {
      const { id, title, status } = mission;

      return {
        ...state,
        list: state.list.map((project) => {
          if (project.id !== projectId) return project;
          return { ...project, missions: [{ id, title, status }, ...project.missions] };
        })
      };
    },
    updateMission: (state, { projectId, mission }) => {
      const { id, title, status } = mission;

      return {
        ...state,
        list: state.list.map((project) => {
          if (project.id !== projectId) return project;

          return {
            ...project,
            missions: project.missions.map((mission) => {
              if (mission.id !== id) return mission;
              return { ...mission, title, status };
            })
          };
        })
      };
    }
  },
  effects: () => ({
    async loadProjects() {
      this.loading();
      try {
        const projects = await getProjects();
        this.setProjects(projects);
        return projects;
      } catch (e) {
        this.loadingFailed();
      }
    },
    async loadCurrentProject(id, rootState) {
      if (id === rootState.reprojects.loadingProjectId) {
        return;
      }
      this.loadingCurrentProject(id);
      try {
        const response = await getProject(id);
        const { missions = [], ...projectWithoutMissions } = response.project;
        this.setCurrentProject({
          missions,
          project: projectWithoutMissions
        });
      } catch (e) {
        this.loadingCurrentProjectFailed();
      }
    },
    async addProject(input, { reprojects }) {
      try {
        const project = await addProject(input);
        const projects = compose(sortByPropCaseInsensitive('title'), append(project))(reprojects.list ?? []);

        this.setCurrentProject(project);
        this.setProjects(projects);
        return project;
      } catch (e) {
        this.loadingFailed();
      }
    },
    async deleteProject(id, { reprojects }) {
      try {
        await deleteProject(id);
        const projects = reject((project) => project.id === id, reprojects.list ?? []);
        this.setProjects(projects);
      } catch (e) {
        this.loadingFailed();
      }
    },
    async loadCategories() {
      try {
        const categories = await getProjectCategories();
        this.setCategories(categories);
      } catch (e) {
        this.setCategories([]);
      }
    }
  })
};
