export default {
  WARNING_MATCHING_FREELANCERS: 20,
  MIN_DESCRIPTION_LENGTH: 500,
  MAX_TITLE_LENGTH: 100,
  MIN_MISSION_SKILLS: 5,
  MIN_HOURLY_RATE: 20,
  MAX_HOURLY_RATE: 1000,
  MAX_FILE_SIZE: 20971520, // BYTES (= 20MB)
  AUTHORIZED_EXTENSIONS: [
    '.png',
    '.jpg',
    '.jpeg',
    '.doc',
    '.docx',
    '.xls',
    '.ai',
    '.sketch',
    '.psd',
    '.pdf',
    '.odp',
    '.ods',
    '.odt'
  ],
  MAX_ATTACHMENTS: 5,
  WORKTIME: Object.freeze({
    FULLTIME: 'fullTime',
    PARTTIME: 'partTime'
  }),
  FULL_REMOTE: 'fullRemote',
  PARTIAL_REMOTE: 'partialRemote',
  PRICING_MODEL: Object.freeze({
    DAILY_RATE: 'dailyRate',
    FIXED_PRICE: 'fixedPrice'
  }),
  ON_SITE: 'onSite',
  ORDERS: Object.freeze({
    MATCHING: 'matching',
    HOURLYRATE: 'hourlyRate',
    LAST_UPDATE_TIME: 'lastUpdateTime'
  }),
  VISIBILITY: Object.freeze({
    VISIBLE: 'visible',
    IN_ADMIN_REVIEW: 'inAdminReview',
    PAUSED_BY_CLIENT: 'pausedByClient'
  })
};
