export default Object.freeze({
  OPENWORK: {
    name: 'OpenWork',
    vat: 'BE0686913913'
  },
  SMART: {
    name: 'Smart',
    vat: 'BE0896755397'
  },
  BEELANCE_SERVICES: {
    name: 'Beelance Services',
    vat: 'CHE108430600TVA'
  }
});
