import { query, mutate } from '../../apollo/client';

export async function createNewSubscription(subscription) {
  const { default: createSubscriptionMutation } = await import('../../graphql/subscription/createSubscription');

  const response = await mutate(createSubscriptionMutation, { subscription });
  return response.data.createSubscription;
}

export async function cancelSubscription() {
  const { default: requestSubscriptionCancellationMutation } = await import(
    '../../graphql/subscription/requestSubscriptionCancellation'
  );
  await mutate(requestSubscriptionCancellationMutation, null, false);
}

export async function failIfHasHiredFreelance() {
  const { default: hasHiredFreelanceForUlteriorMissionQuery } = await import(
    '../../graphql/subscription/hasHiredFreelanceForUlteriorMission'
  );
  await query(hasHiredFreelanceForUlteriorMissionQuery);
}

export async function getSubscriptionPlans() {
  const { default: getSubscriptionPlansQuery } = await import('../../graphql/subscription/getSubscriptionPlans');

  const response = await query(getSubscriptionPlansQuery, null, false);
  return response.data.getSubscriptionPlans;
}

export async function getActiveSubscription() {
  const { default: getActiveSubscriptionQuery } = await import('../../graphql/subscription/getActiveSubscription');

  const response = await query(getActiveSubscriptionQuery, null, false);
  return response.data.getActiveSubscription;
}
