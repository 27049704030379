const toastMainError = (toasterCallback, translate) => e => {
  if (e.key) {
    toasterCallback(translate ? translate(e.key) : translate);
    return true;
  }
  return false;
};

const setFormHookError = (setError, translate = null) => e => {
  if (!e.isBackendError || !e.errors || e.errors.length === 0) {
    return false;
  }
  // This is used to set error on field phone for all error called phoneNumber from backend
  const fieldMap = { phoneNumber: 'phone' };

  for (const error of e.errors) {
    setError(fieldMap[error.field] || error.field, {
      message: translate ? translate(error.key, error.variables) : error.key,
      isTranslated: !!translate // Used with old forms to avoid adding a new "validation:" before an already translated translation
    });
  }
  return true;
};

const toastFirstError = (toasterCallback, translate = null) => e => {
  if (!e.isBackendError || !e.errors || e.errors.length === 0) {
    return false;
  }
  const error = e.errors[0];
  toasterCallback(translate ? translate(error.key, error.variables) : error.key);
  return true;
};

const toastFirstOrMainError = (toasterCallback, translate = null) => e => {
  if (!e.isBackendError) {
    return false;
  }

  if (e.errors.length > 0) {
    const error = e.errors[0];
    toasterCallback(translate ? translate(error.key, error.variables) : error.key);
    return true;
  }

  if (e.key) {
    toasterCallback(translate ? translate(e.key) : e.key);
    return true;
  }

  return false;
};

const fallbackErrorHandler = (toasterCallback, translate) => (error, totalHandled) => {
  const key = error.key || 'validation:serverError';
  if (totalHandled === 0) {
    toasterCallback(translate ? translate(key) : key);
  }
};

const handle = error => ({
  with: (...handlers) => {
    handlers.reduce((totalHandled, handler) => {
      const hasBeenHandled = handler(error, totalHandled);
      return hasBeenHandled ? totalHandled + 1 : totalHandled;
    }, 0);
  }
});

const formatBackendErrors = err => {
  const { message, graphQLErrors = [] } = err || {};
  const thrownError = { message, key: '', errors: [], isBackendError: true };

  for (const error of graphQLErrors) {
    thrownError.message = error.message;
    const mainKey = error.data?.key;
    thrownError.key = !mainKey || mainKey.includes(':') ? mainKey : `validation:${mainKey}`;
    for (const field of error?.data?.fields ?? []) {
      const fieldMessageKey = !field.key || field.key.includes(':') ? field.key : `validation:${field.key}`;
      thrownError.errors.push({ field: field.name, key: fieldMessageKey, variables: field.variables || {} });
    }
  }
  return thrownError;
};

export {
  handle,
  toastMainError,
  setFormHookError,
  toastFirstError,
  toastFirstOrMainError,
  formatBackendErrors,
  fallbackErrorHandler
};
