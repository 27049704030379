import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const InfoCircle = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 10.8a1.2 1.2 0 00-1.2 1.2v4.8a1.2 1.2 0 102.4 0V12a1.2 1.2 0 00-1.2-1.2zm.456-4.704a1.2 1.2 0 00-.912 0 1.2 1.2 0 00-.396.252 1.38 1.38 0 00-.252.396c-.067.142-.1.299-.096.456a1.2 1.2 0 00.348.852c.117.106.25.191.396.252A1.2 1.2 0 0013.2 7.2a1.26 1.26 0 00-.348-.852 1.2 1.2 0 00-.396-.252zM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12A12 12 0 0012 0zm0 21.6a9.6 9.6 0 110-19.2 9.6 9.6 0 010 19.2z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default InfoCircle;
