import { query, mutate } from '../../apollo/client';

import { pathOr } from 'ramda';

export async function getNotifications() {
  const { default: getNotificationsQuery } = await import('../../graphql/notification/getNotifications');

  const response = await query(getNotificationsQuery, null, false);
  return response.data.getNotifications;
}

export async function markNotificationAsRead(notificationId) {
  const { default: readNotificationMutation } = await import('../../graphql/notification/readNotification');
  await mutate(readNotificationMutation, { notificationId }, false);
}
