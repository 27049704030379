import { abstractLocalStorage } from './window/localStorage';
import { abstractSessionStorage } from './window/sessionStorage';
import { isOnServer } from './reactOrNext';

const reactEnv = process.env;
const nextEnv = {
  REACT_APP_ENV: process.env.NEXT_PUBLIC_ENV,
  REACT_APP_API_URL: process.env.NEXT_PUBLIC_API_URL,
  REACT_APP_URL: process.env.NEXT_PUBLIC_URL,
  REACT_APP_TERRITORY: process.env.NEXT_PUBLIC_TERRITORY,
  REACT_APP_GA_CODE: process.env.NEXT_PUBLIC_GA_CODE,
  REACT_APP_GTM_CODE: process.env.NEXT_PUBLIC_GTM_CODE,
  REACT_APP_HOTJAR_ID: process.env.NEXT_PUBLIC_HOTJAR_ID,
  REACT_APP_HOTJAR_SV: process.env.NEXT_PUBLIC_HOTJAR_SV,
  REACT_APP_GOOGLE_SITE_VERIFICATION: process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION,
  REACT_APP_FACEBOOK_ID: process.env.NEXT_PUBLIC_FACEBOOK_ID,
  REACT_APP_STRIPE_PK: process.env.NEXT_PUBLIC_STRIPE_PK,
  REACT_APP_STRIPE_PK_IO: process.env.NEXT_PUBLIC_STRIPE_PK_IO,
  REACT_APP_STRIPE_PK_CH: process.env.NEXT_PUBLIC_STRIPE_PK_CH,
  REACT_APP_DESCRIPTION: process.env.NEXT_PUBLIC_DESCRIPTION,
  REACT_APP_LOCIZE_PROJECT_ID: process.env.NEXT_PUBLIC_LOCIZE_PROJECT_ID,
  REACT_APP_API_TRANSLATIONS_PROJECT_ID: process.env.NEXT_PUBLIC_API_TRANSLATIONS_PROJECT_ID,
  REACT_APP_DEBUG_TRANSLATION: process.env.NEXT_PUBLIC_DEBUG_TRANSLATION,
  REACT_APP_HASH: process.env.NEXT_PUBLIC_HASH,
  REACT_APP_AVAILABLE_LANGUAGES: process.env.NEXT_PUBLIC_AVAILABLE_LANGUAGES,
  REACT_APP_DEFAULT_LANGUAGE: process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE,
  REACT_APP_CACHE_TOKEN: process.env.NEXT_PUBLIC_CACHE_TOKEN
};
const isDevMode = process.env.NODE_ENV === 'development';

export const isNextJsMode = () => !!process.env.NEXT_PUBLIC_ENV && process.env.NODE_ENV !== 'test';

export const getEnv = () => (isNextJsMode() ? nextEnv : reactEnv);

/**
 * @param {string} key
 * @return {string}
 */
export const getCustomConfig = key => {
  return abstractLocalStorage.getItem(key) || '';
};

export const setCustomConfig = (key, value) => {
  if (value) {
    abstractLocalStorage.setItem(key, value);
  } else {
    abstractLocalStorage.removeItem(key);
  }
};

const getConfigByKey = key => {
  if (isOnServer()) {
    return nextEnv[key];
  }
  return getCustomConfig(key) || reactEnv[key] || nextEnv[key];
};

export const isProduction = () => {
  return getConfigByKey('REACT_APP_ENV') === 'production';
};

export const getHash = () => {
  return getConfigByKey('REACT_APP_HASH');
};

export const getRealEnv = () => {
  return process.env.REACT_APP_ENV || process.env.NEXT_PUBLIC_ENV;
};

export const getAppVersion = () => {
  return getConfigByKey('REACT_APP_VERSION');
};

export const getWebsocketUrl = (next = false) => {
  const apiUrl = getApiUrl();
  if (apiUrl) {
    const url = new URL(apiUrl);
    const protocol = url.protocol === 'http:' ? 'ws:' : 'wss:';
    return `${protocol}//${url.host}/subscriptions?next=${next}`;
  }
  return getConfigByKey('REACT_APP_WS');
};

export const getApiUrl = () => {
  return getConfigByKey('REACT_APP_API_URL');
};

export const getGraphQLUrl = (next = false) => {
  const apiUrl = getApiUrl();
  if (apiUrl) {
    const url = new URL(apiUrl);
    return `${url.protocol}//${url.host}/graphql?next=${next}`;
  }
  return getConfigByKey('REACT_APP_API');
};

export const getRatioOfNewApiCall = () => {
  return parseFloat(getConfigByKey('REACT_APP_RATIO_NEW_API')) || 0;
};

export const getAppUrl = () => {
  return getConfigByKey('REACT_APP_URL');
};

export const getStripeKey = () => {
  return getConfigByKey('REACT_APP_STRIPE_PK');
};

export const getHotjarId = () => {
  return getConfigByKey('REACT_APP_HOTJAR_ID');
};

export const getHotjarVersion = () => {
  return getConfigByKey('REACT_APP_HOTJAR_SV');
};

export const getGoogleAnalyticsCode = () => {
  return getConfigByKey('REACT_APP_GA_CODE');
};

export const getGoogleSiteVerification = () => {
  return getConfigByKey('REACT_APP_GOOGLE_SITE_VERIFICATION');
};

export const i18nConfig = {
  isUsingLocizeTranslation() {
    return abstractSessionStorage.getItem('translation') === 'locize' || !!process.env.REACT_APP_FORCE_LOCIZE;
  },
  toggleTranslationOrigin() {
    if (this.isUsingLocizeTranslation()) {
      return abstractSessionStorage.removeItem('translation');
    }
    abstractSessionStorage.setItem('translation', 'locize');
  }
};

export const hasRedAlertOnError = () => {
  return ['local', 'e2e', 'qa', 'staging'].includes(getConfigByKey('REACT_APP_ENV'));
};
export const hasSentryModalOnError = () => {
  return ['preprod'].includes(getConfigByKey('REACT_APP_ENV'));
};

export const isTranslationWarningActivated = () => {
  return ['local', 'development', 'staging', 'qa', 'preprod', 'e2e'].includes(getConfigByKey('REACT_APP_ENV'));
};

export const isTranslationDebugActivated = () => {
  return isDevMode && getConfigByKey('REACT_APP_DEBUG_TRANSLATION') === 'true';
};

export const getLocizeProjectId = () => {
  return getConfigByKey('REACT_APP_LOCIZE_PROJECT_ID');
};

export const getApiTranslationsProjectId = () => {
  return getConfigByKey('REACT_APP_API_TRANSLATIONS_PROJECT_ID');
};

export const getPlatformTerritory = () => {
  if (['ch', 'lu'].includes(getConfigByKey('REACT_APP_TERRITORY'))) {
    return getConfigByKey('REACT_APP_TERRITORY');
  }

  return 'io';
};

/**
 * @return {string[]}
 */
export const getPlatformLanguages = () => {
  return getConfigByKey('REACT_APP_AVAILABLE_LANGUAGES').split(',');
};

export const getPlatformDefaultLanguage = () => {
  return getConfigByKey('REACT_APP_DEFAULT_LANGUAGE');
};

/**
 * @return {string}
 */
export const getCacheToken = () => {
  return getConfigByKey('REACT_APP_CACHE_TOKEN');
};

export const overwriteConfig = entries => {
  if (getRealEnv() === 'production') {
    //Too dangerous to change it via querystring (A hacker could give an url to our admin)
    return false;
  }
  let hasSomethingChanged = false;
  for (const [key, value] of entries) {
    if (key.startsWith('REACT_APP_')) {
      if (abstractLocalStorage.getItem(key) !== value) {
        hasSomethingChanged = true;
        abstractLocalStorage.setItem(key, value);
      }
    }
  }
  return hasSomethingChanged;
};
export const resetConfig = () => {
  for (const key of Object.keys(abstractLocalStorage.all())) {
    if (key.startsWith('REACT_APP_')) {
      abstractLocalStorage.removeItem(key);
    }
  }
};
