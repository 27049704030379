import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

/**
 * use this hook if you want to trigger a callback every X milliseconds (timeToTriggerCallBackInMilliSecond)
 * set onlyWhenUserActive to true if you want to trigger the callback only when the user is active
 * @param {{
   *  callback: () => void;
   *  timeToTriggerCallBackInMilliSecond: number ;
   *  onlyWhenUserActive: boolean;
   *  timeToConsiderUserInactiveInMilliSecond: number;
   * }}
 */
export const useInterval = ({ callback = () => null, timeToTriggerCallBackInMilliSecond = null, onlyWhenUserActive = false, timeToConsiderUserInactiveInMilliSecond = null }) => {
    const savedCallback = useRef(callback);
    const [isUserActive, setIsUserActive] = useState(true);
    const nullFunction = () => null;

    const onUserActive = () => {
        savedCallback.current = callback;
        setIsUserActive(true);
    };

    const onUserIdle = () => {
        savedCallback.current = onlyWhenUserActive ? nullFunction : callback;
        setIsUserActive(false);
    };

    useIdleTimer({
        timeout: timeToConsiderUserInactiveInMilliSecond,
        onActive: onUserActive,
        onIdle: onUserIdle
    });

    useEffect(() => {
        if (isUserActive) {
            savedCallback.current();
        }
    }, [isUserActive]);

    // Set up the interval.
    useEffect(() => {
        // Note: 0 is a valid value for delay.
        if (!timeToTriggerCallBackInMilliSecond && timeToTriggerCallBackInMilliSecond !== 0) {
            return;
        }

        const id = setInterval(() => savedCallback.current(), timeToTriggerCallBackInMilliSecond);

        return () => clearInterval(id);
    }, [onlyWhenUserActive, timeToTriggerCallBackInMilliSecond]);
};