import { format } from './date-fns';
import { isNextJsMode } from './config';

class BeelanceDate {
  /**
   * @param {number} timestamp
   * @returns {BeelanceDate}
   * */
  static fromTimeStamp(timestamp) {
    return new BeelanceDate(new Date(timestamp));
  }

  /**
   * @param {string} str
   * @returns {BeelanceDate}
   * */
  static fromISOString(str) {
    const [year, month, date] = str.split('-').map(curr => Number(curr));
    if ([year, month, date].some(isNaN)) throw new Error(`Invalid ISO date string: ${str}`);
    return new BeelanceDate(new Date(year, month - 1, date));
  }

  /**
   * @param {Date} date
   * */
  constructor(date) {
    this._date = date;
  }

  /**
   * @returns {string}
   * */
  getISOString() {
    return new Date(Date.UTC(this._date.getFullYear(), this._date.getMonth(), this._date.getDate()))
      .toISOString()
      .slice(0, 10);
  }

  /**
   * @returns {Date}
   * */
  getDate() {
    return this._date;
  }

  /**
   * @param {BeelanceDate} otherDate
   * @returns {boolean}
   * */
  isSameDate(otherDate) {
    return this.getISOString() === otherDate.getISOString();
  }

  /**
   * @param {BeelanceDate} startDate
   * @param {BeelanceDate} endDate
   * @returns {boolean}
   * */
  isBetweenOrEqual(startDate, endDate) {
    return this._date >= startDate.getDate() && this._date <= endDate.getDate();
  }

  /**
   * @param {string} formatStr
   * @param {string|null} language must be given in nextjs
   * @returns {string}
   * */
  format(formatStr, language = null) {
    if (isNextJsMode() && !language) {
      throw new Error('"language" not passed in BeelanceDate.format()');
    }
    return format(this.getDate(), formatStr, language);
  }
}

export default BeelanceDate;
