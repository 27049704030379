import { fetchFail, fetching, fetchSuccess } from '../../utils';
import { loadApplications, loadApplicationsByMissionId } from '../applications.service';

export default {
  state: {
    list: null,
    byMissionId: null,
    isLoadingApplications: false,
    isLoadingApplicationsByMissionId: false
  },
  reducers: {
    loading: fetching,
    loadingFailed: fetchFail,
    loadingSuccess: fetchSuccess,

    loadingApplications: state => fetching({ ...state, isLoadingApplications: true }),
    loadingApplicationsByMissionId: state => fetching({ ...state, isLoadingApplicationsByMissionId: true }),
    setApplications: (state, applications) =>
      fetchSuccess({
        ...state,
        list: applications,
        isLoadingApplications: false
      }),
    setApplicationsByMissionId: (state, applicationsByMissionId) =>
      fetchSuccess({
        ...state,
        byMissionId: applicationsByMissionId,
        isLoadingApplications: false
      }),
    replaceApplicationsByMissionId: (state, missionId, applications) =>
      fetchSuccess({
        ...state,
        byMissionId: { ...state.byMissionId, [missionId]: applications },
        isLoadingApplicationsByMissionId: false
      }),
    loadingApplicationsFailed: state =>
      fetchFail({
        ...state,
        list: [],
        isLoadingApplications: false
      }),
    loadingApplicationsByMissionFailed: state =>
      fetchFail({
        ...state,
        byMissionId: {},
        isLoadingApplications: false
      })
  },
  effects: () => ({
    async loadApplications(_, rootState) {
      if (rootState.reapplications.isLoadingApplications) {
        return;
      }
      this.loadingApplications();
      try {
        const applications = await loadApplications();
        const applicationsByMissionId = applications.reduce((applicationsByMissionId, application) => {
          applicationsByMissionId[application.mission.id] = {
            title: application.mission.title,
            applications: [...(applicationsByMissionId?.[application.mission.id]?.applications || []), application]
          };
          return applicationsByMissionId;
        }, {});
        this.setApplications(applications);
        this.setApplicationsByMissionId(applicationsByMissionId);
        return applicationsByMissionId;
      } catch (e) {
        this.loadingApplicationsFailed();
      }
    },
    async loadApplicationsByMissionId(missionId, rootState) {
      if (rootState.reapplications.isLoadingApplicationsByMissionId) {
        return;
      }
      this.loadingApplicationsByMissionId();
      try {
        const applications = await loadApplicationsByMissionId(missionId);
        this.replaceApplicationsByMissionId(missionId, applications);
        return applications;
      } catch (e) {
        this.loadingApplicationsByMissionFailed();
      }
    }
  })
};
