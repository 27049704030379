import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { UserEntity } from '../../../utils/entity/userEntity';
import { useTranslate, changeUserLanguage } from '../../../translate/I18n';
import { useRouter } from 'next/router';
import { userIsLogged } from '../../../utils/utils';
import { getPlatformLanguages } from '../../../utils/config';

/**
 * @type {React.Context<UserEntity>}
 */
export const UserContext = React.createContext(null);
export const useUserContext = () => React.useContext(UserContext);

const createUser = function (userFromRedux) {
  if (!userIsLogged()) {
    return new UserEntity({ isLoading: false, isLogged: false });
  }
  if (userFromRedux) {
    return new UserEntity(userFromRedux);
  }

  return null;
};
// eslint-disable-next-line react/prop-types
const UserContextWithLogic = ({ children, loadCurrentUser, user }) => {
  const { i18n } = useTranslate();
  const router = useRouter();
  useEffect(() => {
    loadCurrentUser();
  }, [loadCurrentUser]);

  const userEntity = createUser(user);
  if (userEntity && userEntity.isLoggedIn()) {
    const userLocale = userEntity.getLanguage().toLowerCase();
    if (i18n.language !== undefined && i18n.language.toLowerCase() !== userLocale) {
      const availableLanguages = getPlatformLanguages().map(l => l.toLowerCase());
      if (availableLanguages.includes(userLocale)) {
        const locale = changeUserLanguage(i18n, userEntity.getLanguage());
        router.push(router.route, router.asPath, { locale });
      } else {
        changeUserLanguage(i18n, i18n.language);
      }
    }
  }
  return <UserContext.Provider value={userEntity}>{children}</UserContext.Provider>;
};

const mapStateToProps = state => ({ user: state.reauth.user });
const mapDispatchToProps = ({ reauth: { loadCurrentUser } }) => ({ loadCurrentUser: loadCurrentUser });

export const UserProvider = compose(connect(mapStateToProps, mapDispatchToProps))(UserContextWithLogic);
