import { query, mutate } from '../../apollo/client';

export async function loadApplications() {
  const { default: getApplications } = await import('../../graphql/application/getApplications');

  const response = await query(getApplications);
  return response.data.getApplications;
}

export async function loadApplication(applicationId) {
  const { default: getApplication } = await import('../../graphql/application/getApplication');

  const response = await query(getApplication, { applicationId });
  return response.data.getApplication;
}

export async function loadApplicationsByMissionId(missionId) {
  const { default: getMissionApplications } = await import('../../graphql/application/getMissionApplications');

  const response = await query(getMissionApplications, { missionId }, false);
  return response.data.getMissionApplications;
}

export async function interview(variables) {
  const { default: interviewQuery } = await import('../../graphql/application/interview');

  const response = await mutate(interviewQuery, variables);
  return response.data.interview;
}

export async function hire(variables) {
  const { default: hireQuery } = await import('../../graphql/application/hire');

  const response = await mutate(hireQuery, variables);
  return response.data.hire;
}

export async function reject(variables) {
  const { default: rejectQuery } = await import('../../graphql/application/reject');

  const response = await mutate(rejectQuery, variables);
  return response.data.reject;
}
