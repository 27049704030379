import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Cross = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.611 12l4.915-4.903a1.148 1.148 0 00-1.623-1.623L12 10.39 7.097 5.474a1.148 1.148 0 00-1.623 1.623L10.39 12l-4.915 4.903a1.143 1.143 0 000 1.623 1.143 1.143 0 001.623 0L12 13.61l4.903 4.915a1.143 1.143 0 001.623 0 1.143 1.143 0 000-1.623L13.61 12z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Cross;
