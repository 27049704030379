export default Object.freeze({
  SUBSCRIPTION_STATUS: {
    PENDING: 'pending',
    ACTIVE: 'active',
    ARCHIVED: 'archived',
    REJECTED: 'rejected',
    PENDING_ARCHIVED: 'pending_archived'
  },
  SUBSCRIPTION_FREQUENCE: {
    YEARLY: 'yearly',
    MONTHLY: 'monthly'
  },
  SUBSCRIPTION_PLANS: {
    ESSENTIAL: 'essential',
    PROFESSIONAL: 'professional',
    CORPORATE: 'corporate',
    ENTERPRISE: 'enterprise'
  },
  SUBSCRIPTION_OPTIONS: {
    FULL: 'full',
    EMPTY: 'empty'
  },
  PAYMENT_STATUS: {
    PENDING: 'pending',
    PAID: 'paid',
    CANCELLED: 'cancelled'
  },
  PAYMENT_TYPES: {
    INVOICE: 'invoice',
    CREDIT_CARD: 'creditCard'
  }
});
