import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Facebook = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 1l-.225.004A6 6 0 008 7v2H6a1 1 0 00-1 1v4l.007.117A1 1 0 006 15h2v7a1 1 0 001 1h4l.117-.007A1 1 0 0014 22v-7h2a1 1 0 00.97-.757l1-4 .021-.112A1 1 0 0017 9h-3V7h3a1 1 0 001-1V2a1 1 0 00-1-1h-3zm2 2v2h-2a2 2 0 00-2 2v3l.007.117A1 1 0 0013 11h2.718l-.5 2H13l-.117.007A1 1 0 0012 14v7h-2v-7l-.007-.117A1 1 0 009 13H7v-2h2a1 1 0 001-1V7a4 4 0 014-4h2z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Facebook;
