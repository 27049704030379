export default {
  noCureNoPayMeeting: {
    FR: 'https://meetings.hubspot.com/lph/custom-plan',
    NL: 'https://meetings.hubspot.com/mvn/no-cure-no-pay',
    EN: 'https://meetings.hubspot.com/mvn/no-cure-no-pay'
  },
  productTourMeeting: {
    FR: 'https://meetings.hubspot.com/lph',
    NL: 'https://meetings.hubspot.com/mvn/beelance-introduction',
    EN: 'https://meetings.hubspot.com/mvn/beelance-introduction'
  },
  blog: 'https://blog.beelance.io/',
  securex:
    'https://www.securex.be/en/colab-entrepreneurs-en/start-to-freelance?utm_source=beelance&utm_medium=referral&utm_content=:userId',
  schema: {
    faq: 'https://schema.org/FAQPage',
    question: 'http://schema.org/Question',
    answer: 'http://schema.org/Answer'
  },
  community: {
    silversquare: {
      bookATour: 'https://silversquare.eu/book-a-tour/?lang=:lang',
      brochure: 'https://files.silversquare.eu/beelance/SQ_Pricing.pdf'
    }
  },
  discord: {
    freelancersCommunity: 'https://discord.com/widget?id=737599483142996019&theme=dark',
    connect: 'https://discord.com/invite/vb74mXVg4e'
  }
};
