import { fetchFail, fetching, fetchSuccess } from '../../utils';

import { addResume, deleteResume, getProfile, setProfile, uploadAvatar } from '../profile.service';

export default {
  state: { profile: null, isFetching: false, hasError: false, saving: false },
  reducers: {
    loading: fetching,
    loadingFailed: fetchFail,
    loadingSuccess: fetchSuccess,
    setProfile: (state, { profile }) => fetchSuccess({ ...state, profile }),
    setHasResume: (state, hasResume) => fetchSuccess({ ...state, profile: { ...state.profile, hasResume: hasResume } }),
    setAvatarLink: (state, avatarLink) => fetchSuccess({ ...state, profile: { ...state.profile, avatarLink } }),
    loadingProfileFailed: state => fetchFail({ ...state, profile: {} }),
    loadingResumeFailed: state => fetchFail({ ...state, profile: { ...state.profile, resumeLink: '' } }),
    saving: state => ({ ...state, saving: true }),
    savingFailed: state => ({ ...state, saving: false }),
    savingSuccess: state => ({ ...state, saving: false }),
    uploadingAvatarFailed: state => fetchFail({ ...state }),
    uploadingResumeFailed: state => fetchFail({ ...state }),
    deletingResumeFailed: state => fetchFail({ ...state })
  },
  effects: dispatch => ({
    async loadProfile() {
      this.loading();
      try {
        const response = await getProfile();
        let { profile } = response;
        profile = profile || {};
        this.setProfile({ profile });
        return profile;
      } catch (e) {
        this.loadingProfileFailed();
      }
    },
    async addResume(file) {
      try {
        await addResume(file);
        this.setHasResume(true);
      } catch (e) {
        this.uploadingResumeFailed();
      }
    },
    async deleteResume() {
      try {
        this.setHasResume(false);
        await deleteResume();
      } catch (e) {
        this.setHasResume(true);
        this.deletingResumeFailed();
      }
    },
    async uploadAvatar(file) {
      try {
        const avatarLink = (await uploadAvatar(file)) || '';
        this.setAvatarLink(avatarLink);
      } catch (e) {
        this.uploadingAvatarFailed();
      }
    },
    async updateProfile(profile) {
      this.saving();
      try {
        const savedProfile = await setProfile(profile);
        this.setProfile({ profile: savedProfile });
        dispatch({ type: 'reauth/loadRegistrationStatus' });
        this.savingSuccess();
      } catch (e) {
        this.savingFailed();
        throw e;
      }
    }
  })
};
