import { isInBrowser } from '../reactOrNext';

const serverSessionStorage = {};

/**
 * @param {string} key
 * @return {string|null}
 */
const getItem = key => {
  if (isInBrowser()) {
    return window.sessionStorage.getItem(key);
  }
  return serverSessionStorage[key] || null;
};

/**
 *
 * @param {string} key
 * @param {string} value
 * @return {void}
 */
const setItem = (key, value) => {
  if (isInBrowser()) {
    window.sessionStorage.setItem(key, value);
  }
  serverSessionStorage[key] = value;
};

/**
 * @param {string} key
 * @return {void}
 */
const removeItem = key => {
  if (isInBrowser()) {
    window.sessionStorage.removeItem(key);
  }
  delete serverSessionStorage[key];
};

const all = () => {
  if (isInBrowser()) {
    const values = {};
    for (let i = 0; i < window.sessionStorage.length; i++) {
      values[window.sessionStorage.key(i)] = window.sessionStorage.getItem(window.sessionStorage.key(i));
    }
    return values;
  }
  return { ...serverSessionStorage };
};

export const abstractSessionStorage = { getItem, setItem, all, removeItem };
