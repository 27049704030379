import { client, getClient, mutate, query, removeTypenames } from '../../apollo/client';

export async function addResume(variables) {
  const { default: addResumeQuery } = await import('../../graphql/profile/addResume');

  const response = await mutate(addResumeQuery, variables, false);
  return response.data.addResume;
}

export async function deleteResume() {
  const { default: deleteResumeQuery } = await import('../../graphql/profile/deleteResume');

  return mutate(deleteResumeQuery, null, false);
}

export async function uploadAvatar(variables) {
  const { default: uploadAvatarQuery } = await import('../../graphql/profile/uploadAvatar');

  const response = await mutate(uploadAvatarQuery, variables, false);

  return response.data.uploadAvatar;
}

export async function getResume(variables) {
  const { default: getResumeQuery } = await import('../../graphql/profile/getResume');

  const response = query(getResumeQuery, variables, false);
  return response.data.getResume;
}

export async function getProfile() {
  const { default: getProfileQuery } = await import('../../graphql/profile/getProfile');

  const response = await query(getProfileQuery, null, false);
  return {
    profile: response.data.getProfile
  };
}

export async function getProfilePreferencesByToken(token) {
  const { default: getProfilePreferences } = await import('../../graphql/profile/getProfilePreferences');

  const response = await getClient(token).query({
    query: getProfilePreferences,
    fetchPolicy: 'no-cache'
  });

  return removeTypenames(response.data.getProfile);
}

/**
 * @return {import('../../components/profile/ProfileInfo.type').ProfilePreferencesType}
 */
export async function getDefaultProfilePreferences() {
  const { default: getDefaultProfilePreferencesQuery } = await import(
    '../../graphql/profile/getDefaultProfilePreferences'
  );
  const response = await query(getDefaultProfilePreferencesQuery);
  return response.data.getDefaultProfilePreferences;
}

export async function setProfile({ profile }) {
  const { default: setProfileMutation } = await import('../../graphql/profile/setProfile');

  const response = await mutate(setProfileMutation, { profile }, false);
  return response.data.setProfile;
}

export async function getResumeLink(id) {
  const { default: getResumeQuery } = await import('../../graphql/profile/getResume');

  const response = await query(getResumeQuery, { id });
  return response.data.getResume;
}

export async function getProofOfCertificationLink({ filepath }) {
  const { default: getProofOfCertificationLinkQuery } = await import('../../graphql/profile/getProofOfCertificationLink');

  const response = await query(getProofOfCertificationLinkQuery, { filepath });
  return response.data.getProofOfCertificationLink;
}

export async function askValidationProfile(userId) {
  const { default: askValidationProfileMutation } = await import('../../graphql/profile/askValidationProfile');

  const response = await mutate(askValidationProfileMutation, { userId });
  return response.data.askValidationProfile;
}

export async function cancelValidationProfile(userId) {
  const { default: cancelValidationProfileMutation } = await import('../../graphql/profile/cancelValidationProfile');

  const response = await mutate(cancelValidationProfileMutation, { userId }, false);
  return response.data.cancelValidationProfile;
}

export async function markValidationProgressAsNotified(userId) {
  const { default: confirmNotifyValidationMutation } = await import(
    '../../graphql/profile/markValidationProgressAsNotified'
  );

  const response = await mutate(confirmNotifyValidationMutation, { userId }, false);
  return response.data.markValidationProgressAsNotified;
}

/**
 * @returns {Promise<boolean>}
 */
export async function uploadLinkedinZip(zip) {
  const { default: uploadLinkedinZipMutatation } = await import('../../graphql/profile/uploadLinkedinZip');

  const response = await mutate(uploadLinkedinZipMutatation, { zip });
  return response.data.uploadLinkedinZip;
}
