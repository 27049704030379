import { takeLatest } from 'redux-saga/effects';
import { pathOr } from 'ramda';
import {
  fetchTimeSheets,
  updateDailyTimeSheet,
  submitTimesheet,
  approveTimeSheet,
  rejectTimeSheet
} from './timesheet.service';
import {
  MONTHLY_TIMESHEET,
  UPDATE_DAILY_TIMESHEET,
  SUBMIT_TIMESHEET,
  APPROVE_TIMESHEET,
  REJECT_TIMESHEET
} from './timesheet.actionTypes';
import { queryNames } from './timesheet.queryNames';
import basicSaga from '../../utils/basicSaga';

export const monthlyTimeSheetSaga = basicSaga({
  service: fetchTimeSheets,
  type: MONTHLY_TIMESHEET,
  createSuccessActionPayload: (data) => pathOr({}, ['data', queryNames.getTimeSheets], data)
});

export const updateDailyTimeSheetSaga = basicSaga({
  service: updateDailyTimeSheet,
  type: UPDATE_DAILY_TIMESHEET,
  serviceParamsPath: ['payload', 'mutationVariables'],
  createSuccessActionPayload: (data, action) => {
    const updatedMonthlyTS = pathOr({}, ['data', queryNames.saveHours], data);
    const dailyTimeSheetData = pathOr({}, ['payload', 'dailyTimeSheetData'], action);
    return { ...dailyTimeSheetData, updatedMonthlyTS };
  }
});

const createPayloadWithStatus = (queryName) => (data, action) => {
  const updatedStatus = pathOr('', ['data', queryName, 'status'], data);
  const monthlyTimeSheetData = pathOr({}, ['payload', 'monthlyTimeSheetData'], action);
  return { ...monthlyTimeSheetData, updatedStatus };
};

export const submitTimeSheetSaga = basicSaga({
  service: submitTimesheet,
  type: SUBMIT_TIMESHEET,
  serviceParamsPath: ['payload', 'mutationVariables'],
  createSuccessActionPayload: createPayloadWithStatus(queryNames.submitTimeSheet)
});

export const approveTimeSheetSaga = basicSaga({
  service: approveTimeSheet,
  type: APPROVE_TIMESHEET,
  serviceParamsPath: ['payload', 'mutationVariables'],
  createSuccessActionPayload: createPayloadWithStatus(queryNames.validateTimeSheet)
});

export const rejectTimeSheetSaga = basicSaga({
  service: rejectTimeSheet,
  type: REJECT_TIMESHEET,
  serviceParamsPath: ['payload', 'mutationVariables'],
  createSuccessActionPayload: createPayloadWithStatus(queryNames.rejectTimeSheet)
});

export default function* sagas() {
  yield takeLatest(MONTHLY_TIMESHEET, monthlyTimeSheetSaga);
  yield takeLatest(UPDATE_DAILY_TIMESHEET, updateDailyTimeSheetSaga);
  yield takeLatest(SUBMIT_TIMESHEET, submitTimeSheetSaga);
  yield takeLatest(APPROVE_TIMESHEET, approveTimeSheetSaga);
  yield takeLatest(REJECT_TIMESHEET, rejectTimeSheetSaga);
}
