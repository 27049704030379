import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const LogIn = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.625 1c1.797 0 3.267 1.374 3.37 3.106L23 4.3v15.4c0 1.757-1.405 3.194-3.177 3.294l-.198.006h-4.5A1.113 1.113 0 0114 21.9c0-.564.434-1.03.994-1.093l.131-.007h4.5c.577 0 1.052-.425 1.117-.972l.008-.128V4.3a1.11 1.11 0 00-.994-1.093l-.131-.007h-4.5A1.113 1.113 0 0114 2.1c0-.564.434-1.03.994-1.093L15.125 1h4.5zm-8.782 5.227l.108.09 5.714 5.417c.412.39.444 1.005.095 1.43l-.095.102-5.714 5.417a1.188 1.188 0 01-1.616 0 1.042 1.042 0 01-.095-1.43l.095-.102L13.712 13H2.072C1.48 13 1 12.552 1 12c0-.513.414-.936.946-.993L2.071 11h10.587L9.335 7.85a1.042 1.042 0 01-.095-1.43l.095-.103a1.19 1.19 0 011.508-.09z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default LogIn;
