import { client } from '../../apollo/client';
import cookie from 'react-cookies';

export const logoutUser = async () => {
  try {
    localStorage.removeItem('token');
    removeTokenFromCookie();
    await client.clearStore().then(() => window.location.replace('/'));
  } catch (error) { }
};

export const saveTokenInCookie = token => {
  cookie.save('token', token, { path: '/' });
};

export const removeTokenFromCookie = () => {
  cookie.remove('token', { path: '/' });
};
