import React from 'react';
import { isNextJsMode } from './config';
import PropTypes from 'prop-types';
import { useRouter as useNextJsRouter, withRouter as withNextJsRouter } from 'next/router';
import {
  useHistory as useReactHistory,
  Redirect as ReactRedirect,
  Link as ReactLink,
  useParams as useReactParams
} from 'react-router-dom';
import { withRouter as withReactRouter } from 'react-router';
import NextJsLink from 'next/link';
import { isInBrowser } from './reactOrNext';

export const withRouter = (WrappedComponent, componentProps = {}) => {
  if (isNextJsMode()) {
    const RouteredComponent = props => {
      // eslint-disable-next-line react/prop-types
      props.router.location = {
        // eslint-disable-next-line react/prop-types
        pathname: props.router.asPath
      };
      // eslint-disable-next-line react/prop-types
      return <WrappedComponent {...componentProps} {...props} history={props.router} location={props.router} />;
    };
    return withNextJsRouter(RouteredComponent);
  }
  return withReactRouter(WrappedComponent, componentProps);
};

export const useRouter = () => {
  if (isNextJsMode()) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const router = useNextJsRouter();
    router.goBack = () => router.back();
    return router;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactHistory();
};

export const useParams = () => {
  if (isNextJsMode()) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const router = useNextJsRouter();
    return router.query;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useReactParams();
};

export const Redirect = ({ to }) => {
  const router = useRouter();
  if (isNextJsMode()) {
    if (!isInBrowser()) {
      // eslint-disable-next-line no-console
      console.warn(
        '[BEELANCE ERROR] You tried to redirect in server mode with <Redirect />.',
        'We protect a compilation error, but please fix this issue',
        'Here is the stack (sorry, it is the compiled stack...)',
        new Error().stack
      );
      return null;
    }
    router.push(to);
    return null;
  }
  return <ReactRedirect to={to} />;
};
Redirect.propTypes = {
  to: PropTypes.string
};

export const Link = props => {
  if (isNextJsMode()) {
    const { to, children, type, ...rest } = props;
    return (
      <NextJsLink href={to}>
        <a {...rest}>{children}</a>
      </NextJsLink>
    );
  }
  return <ReactLink {...props} />;
};

Link.propTypes = {
  to: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node
};
