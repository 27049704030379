import { call, put } from 'redux-saga/effects';
import { path, pathOr } from 'ramda';
import { toast } from 'react-toastify';

import { createEmptyObject } from './utils';
import i18n from '../translate/I18n';
import { ACTIONS } from '../constants';

const { _SUCCESS, _FAILURE } = ACTIONS;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ service, type, createSuccessActionPayload = createEmptyObject, serviceParamsPath = ['payload'] }) =>
  function* (action) {
    const skipSaga = pathOr(false, ['payload', '__skipSaga'], action);
    if (skipSaga) {
      return;
    }
    try {
      const data = yield call(service, path(serviceParamsPath, action));
      const payload = createSuccessActionPayload(data, action);

      yield put({ type: `${type}${_SUCCESS}`, payload: { ...payload, translate: action.payload.translate } });

      const successMsg = action.payload.translate
        ? action.payload.translate(`notificationSuccess:${type}`, { defaultValue: '' })
        : i18n.t(`notificationSuccess:${type}`, { defaultValue: '' });
      if (successMsg) toast.success(successMsg);
    } catch (error) {
      yield put({ type: `${type}${_FAILURE}`, error });
    }
  };
