import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Package = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.665.554a3 3 0 012.67 0l8 4A3 3 0 0123 7.24v9.53a3 3 0 01-1.663 2.684l-8 4a3 3 0 01-2.684 0L2.65 19.453A2.998 2.998 0 011 16.76V7.24a3 3 0 011.663-2.684l3.81-1.906c.048-.03.1-.056.152-.077zM3 7.618v9.149a1 1 0 00.547.899L11 21.392v-9.774l-8-4zm18 0l-8 4v9.768l7.445-3.722a1 1 0 00.547-.77L21 16.77V7.618zM7.005 4.62L4.24 6.002 12 9.881l2.762-1.382L7.005 4.62zm5.44-2.274a.999.999 0 00-.888-.002L9.241 3.502l7.758 3.88 2.759-1.38z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Package;
