import { fetchFail, fetching, fetchSuccess } from '../../utils';
import { descend, prop, sort, reject, propOr, path, find } from 'ramda';
import {
  deleteMission
} from '../missions.service';

function getUpdatedMission(missionId, updatedProjectMissions) {
  return find(mission => mission.id === missionId)(updatedProjectMissions);
}

function getNewMission(currentProjectMissions, updatedProjectMissions) {
  const currentProjectMissionIds = currentProjectMissions.reduce((acc, { id }) => {
    acc[id] = true;
    return acc;
  }, {});
  return find(mission => !currentProjectMissionIds[mission.id])(updatedProjectMissions);
}

export default {
  state: {
    matchingRoles: null,
    locations: null,
    roles: [],
    isFetching: false,
    hasError: false,
    loadingSearch: false,
    loadingMission: null,
    loadingMatchingRoles: false,
    isLoadingRoles: false
  },
  reducers: {
    loading: fetching,
    loadingFailed: fetchFail,
    loadingSuccess: fetchSuccess,
    loadRolesFailed: state => fetchFail({ ...state, roles: [], isLoadingRoles: false }),
    saveMissionFailed: state => fetchFail({ ...state }),
    'reprojects/setCurrentProject': (state, { missions }) => {
      return {
        ...state,
        listOfProjectMissions: sort(descend(prop('creationDate')), missions)
      };
    },
    'reprojects/loadingCurrentProjectFailed': state => {
      return { ...state, listOfProjectMissions: [], hasError: true };
    },
    setProjectMissionsList: (state, { missions }) => fetchSuccess({ ...state, listOfProjectMissions: missions })
  },
  effects: dispatch => ({
    updateLists({ mission, projectId, project }, rootState) {
      const { missions = [] } = project;
      this.setProjectMissionsList(missions);

      if (mission.id) {
        const updatedMission = getUpdatedMission(mission.id, missions);
        dispatch.reprojects.updateMission({ projectId, mission: updatedMission });
      } else {
        const updatedMission = getNewMission(rootState.remissions.listOfProjectMissions || [], missions);
        dispatch.reprojects.addMission({ projectId, mission: updatedMission });
      }
    },
    async deleteMission({ projectId, missionId }, rootState) {
      try {
        await deleteMission({ missionId, projectId });
        const missions = reject(
          mission => mission.id === missionId,
          propOr([], 'listOfProjectMissions', rootState.remissions)
        );
        this.setProjectMissionsList(missions);
      } catch (e) {
        this.loadingFailed();
        throw e;
      }
    }
  })
};
