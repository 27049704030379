import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Linkedin = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16 8a7 7 0 016.996 6.76L23 15v7a1 1 0 01-.883.993L22 23h-4a1 1 0 01-.993-.883L17 22v-7a1 1 0 00-1.993-.117L15 15v7a1 1 0 01-.883.993L14 23h-4a1 1 0 01-.993-.883L9 22v-7a7 7 0 017-7zM6 9a1 1 0 01.993.883L7 10v12a1 1 0 01-.883.993L6 23H2a1 1 0 01-.993-.883L1 22V10a1 1 0 01.883-.993L2 9h4zm10 1a5 5 0 00-4.995 4.783L11 15v6h2v-6a3 3 0 012.65-2.98l.174-.015L16 12a3 3 0 012.995 2.824L19 15v6h2v-6a5 5 0 00-4.563-4.981l-.22-.014L16 10zM5 11H3v10h2V11zM4 2a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Linkedin;
