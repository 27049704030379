import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const User = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.601 11.52a5.371 5.371 0 001.856-4.061 5.459 5.459 0 00-10.917 0c0 1.558.677 3.04 1.856 4.06a8.734 8.734 0 00-5.131 7.948 1.092 1.092 0 102.183 0 6.55 6.55 0 0113.101 0 1.092 1.092 0 102.183 0 8.734 8.734 0 00-5.13-7.947zM12 10.734a3.275 3.275 0 110-6.55 3.275 3.275 0 010 6.55z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default User;
