import { mutate, query } from '../../apollo/client';

export async function addProject(project) {
  const { default: createOrUpdateProject } = await import('../../graphql/project/createOrUpdateProject');

  const response = await mutate(createOrUpdateProject, { project }, false);
  return response.data.createOrUpdateProject;
}

export async function deleteProject(projectId) {
  const { default: deleteProjectMutation } = await import('../../graphql/project/deleteProject');

  const response = await mutate(deleteProjectMutation, { projectId }, false);
  return response.data.deleteProject;
}

export async function getProjects() {
  const { default: fetchProjectsQuery } = await import('../../graphql/project/getProjects');

  const response = await query(fetchProjectsQuery, null, false);
  return response.data.getProjects;
}

export async function getProject(id) {
  const { default: getProjectQuery } = await import('../../graphql/project/getProject');

  const response = await query(getProjectQuery, { projectId: id }, false);
  return {
    project: response.data.getProject
  };
}

export async function getProjectCategories() {
  const { default: getProjectCategoriesQuery } = await import('../../graphql/project/getProjectCategories');

  const response = await query(getProjectCategoriesQuery, null, false);
  return response.data.getProjectCategories;
}
