import { fetchFail, fetching, fetchSuccess } from '../../utils';
import {
  getCompanyUsers,
  linkUserToCompany,
  updateCompanyInfo,
  uploadCompanyLogo,
  deleteCompanyUser,
  rejectCompanyUser,
  updateUserRole
} from '../settings.service';

export default {
  state: {
    company: null,
    isFetching: false,
    hasError: false
  },
  reducers: {
    loading: fetching,
    loadingFailed: fetchFail,
    loadingSuccess: fetchSuccess,
    setCompany: (state, company) => fetchSuccess({ ...state, company, isFetchingCompany: false }),
    setCompanyUsers: (state, { corporateUsers, pendingInvitationUsers, users }) =>
      fetchSuccess({
        ...state,
        teamUsers: { companyUsers: users, corporateUsers, pendingUsers: pendingInvitationUsers }
      }),
    loadingCompanyUsersFailed: state =>
      fetchFail({
        ...state,
        teamUsers: { companyUsers: [], corporateUsers: [], pendingUsers: [] }
      })
  },
  effects: dispatch => ({
    async deleteUser(email) {
      this.loading();
      try {
        this.setCompanyUsers(await deleteCompanyUser(email));
      } catch (e) {
        //Done by toaster
      }
    },
    async rejectUser(email) {
      this.loading();
      try {
        this.setCompanyUsers(await rejectCompanyUser(email));
      } catch (e) {
        //Done by toaster
      }
    },
    async updateUserRole({ email, role }) {
      this.loading();
      try {
        this.setCompanyUsers(await updateUserRole(email, role));
      } catch (e) {
        //Done by toaster
      }
    },
    async loadCompanyUsers() {
      this.loading();
      try {
        const users = await getCompanyUsers();
        this.setCompanyUsers(users);
      } catch (e) {
        this.loadingCompanyUsersFailed();
      }
    },
    async linkUserToCompany(companyInfo) {
      this.loading();
      try {
        const updatedCompany = await linkUserToCompany(companyInfo);
        this.setCompany(updatedCompany);
        dispatch({ type: 'reauth/loadCurrentUser' });
        dispatch({ type: 'reauth/loadRegistrationStatus' });
        return updatedCompany;
      } catch (e) {
        this.loadingFailed();
        throw e;
      }
    },
    async updateCompanyInfo(companyInfo) {
      this.loading();
      try {
        const company = await updateCompanyInfo(companyInfo);
        this.setCompany(company);
        return company;
      } catch (e) {
        this.loadingFailed();
      }
    },
    async uploadLogo(file) {
      return await uploadCompanyLogo(file);
    }
  })
};
