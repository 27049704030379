import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const UserPlus = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 14a5 5 0 014.995 4.783L17 19v2a1 1 0 01-1.993.117L15 21v-2a3 3 0 00-2.824-2.995L12 16H5a3 3 0 00-2.995 2.824L2 19v2a1 1 0 01-1.993.117L0 21v-2a5 5 0 014.783-4.995L5 14h7zm8-7a1 1 0 01.993.883L21 8v2h2a1 1 0 01.117 1.993L23 12h-2v2a1 1 0 01-1.993.117L19 14v-2h-2a1 1 0 01-.117-1.993L17 10h2V8a1 1 0 011-1zM8.5 2a5 5 0 110 10 5 5 0 010-10zm0 2a3 3 0 100 6 3 3 0 000-6z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default UserPlus;
