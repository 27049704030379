import ROLES from '../../constants/roles';

export class UserEntity {
  constructor(user) {
    for (let [key, value] of Object.entries(user)) {
      this[key] = value;
    }
    this.isLoading = user.isLoading ?? false;
  }

  /**
   * @returns {string}
   */
  getId() {
    return this.id;
  }

  /**
   * @param {string} id
   * @returns boolean
   */
  isCurrentUser(id) {
    return this.getId() === id;
  }

  /**
   * @returns {string}
   */
  getRole() {
    return this.role;
  }

  /**
   * @returns {string}
   */
  getCompanyId() {
    return this.company?.id;
  }

  /**
   * @returns {boolean}
   */
  isFreelance() {
    return this.role === ROLES.FREELANCE;
  }

  getLanguage() {
    return this.language;
  }

  /**
   * @returns {boolean}
   * @deprecated use isClient()
   */
  isCompany() {
    return this.isClient();
  }

  /**
   * @returns {boolean}
   */
  isClient() {
    return this.role === ROLES.COMPANY;
  }

  /**
   * @returns {boolean}
   */
  isEmailVerified() {
    return this.registrationProgress?.isEmailVerified;
  }

  /**
   * @returns {boolean}
   */
  hasCompanyAndEmailVerified() {
    return this.isEmailVerified() && this.hasCompany();
  }

  /**
   * @returns {boolean}
   */
  isCorporate() {
    return this.subRole === ROLES.COMPANY_SUB_ROLES.CORPORATE;
  }

  /**
   * @returns {boolean}
   */
  hasRole(role) {
    return this.role === role;
  }

  /**
   * @returns {boolean}
   */
  isLoggedIn() {
    return !!this.isLogged;
  }

  /**
   * @returns {boolean}
   */
  hasCompany() {
    return !!this.companyVat;
  }

  /**
   * @param {number} partnerUpdateDate
   * @returns {boolean}
   */
  hasNewPartner(partnerUpdateDate) {
    return partnerUpdateDate > this.lastCommunityPageView;
  }
}
