import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const Users = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.336 11.52a5.371 5.371 0 001.856-4.061 5.459 5.459 0 00-10.917 0c0 1.558.677 3.04 1.856 4.06A8.734 8.734 0 000 19.468a1.092 1.092 0 102.183 0 6.55 6.55 0 0113.101 0 1.092 1.092 0 102.183 0 8.734 8.734 0 00-5.13-7.947zm-3.602-.786a3.275 3.275 0 110-6.55 3.275 3.275 0 010 6.55zm10.633.349A5.459 5.459 0 0015.284 2a1.092 1.092 0 000 2.183 3.275 3.275 0 013.275 3.276 3.275 3.275 0 01-1.637 2.827 1.092 1.092 0 00-.055 1.856l.426.284.142.076a7.642 7.642 0 014.366 6.965 1.092 1.092 0 002.184 0 9.825 9.825 0 00-4.618-8.384z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default Users;
