import { equals } from 'ramda';
import createSagaMiddleware from 'redux-saga';
import createReduxWaitForMiddleware from 'redux-wait-for-action';

import { reducers } from './reducers';
import sagas from './sagas';

import { ACTIONS } from '../constants';
import { init } from '@rematch/core';
import { models } from './models';
import { useMemo } from 'react';

const { _FAILURE } = ACTIONS;

let previousAction = {};

const avoidInfiniteBECalls = () => next => action => {
  const { type: previousActionType = '' } = previousAction;
  const requestType = previousActionType.includes(_FAILURE) ? previousActionType.replace(_FAILURE, '') : '';

  if (!equals(previousAction, action) && action.type !== requestType) {
    previousAction = action;
    return next(action);
  }
};

const sagaMiddleware = createSagaMiddleware();
const store = init({
  models,
  redux: {
    reducers: reducers,
    middlewares: [sagaMiddleware, createReduxWaitForMiddleware(), avoidInfiniteBECalls]
  }
});

/**
 * @param {function(store):any} select What you want to observe
 * @return any
 */
const selectInStore = select => select(store.getState());

/**
 *
 * @param {function(store):any} select What you want to observe
 * @param { function(newValue:any):any } onChange called when the observing value is changing
 * @return {{unsubscribe: function():void, value: any}}
 */
const observeStore = (select, onChange) => {
  let currentState = selectInStore(select);

  function handleChange() {
    let nextState = selectInStore(select);
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  return { value: currentState, unsubscribe: store.subscribe(handleChange) };
};

const dispatch = store.dispatch;
const actionOn = store.dispatch;
sagaMiddleware.run(sagas);
export default store;

export function useStore() {
  return useMemo(() => store, []);
}

export { dispatch, actionOn, observeStore, selectInStore };
