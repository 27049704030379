import Clock from '../components/atoms/icons/Clock';
import File from '../components/atoms/icons/File';
import Globe from '../components/atoms/icons/Globe';
import InfoCircle from '../components/atoms/icons/InfoCircle';
import HelpCircle from '../components/atoms/icons/HelpCircle';
import LogIn from '../components/atoms/icons/LogIn';
import Package from '../components/atoms/icons/Package';
import Suitcase from '../components/atoms/icons/Suitcase';
import House from '../components/atoms/icons/House';
import User from '../components/atoms/icons/User';
import Users from '../components/atoms/icons/Users';
import UserPlus from '../components/atoms/icons/UserPlus';
import territorySwitch from '../components/territory/territorySwitch';

const landingPagePath = '/lp/:categoryName/:role?';
const landingPagePathLocal = '/:local/lp/:categoryName/:role?';
const regexPathSegment = () => landingPagePath.replace(/:.*/, '(.*)/');
const regexLastPathSegment = () => landingPagePath.replace(/:.*/, '(.*)');

const getMatchingText = matchingText => {
  if (matchingText && matchingText.length) {
    for (let i = 1; i < matchingText.length; i++)
      if (matchingText[i]) {
        return matchingText[i];
      }
  }
  return null;
};

const extractCategoryName = pathname => {
  const matchingText = pathname.match(regexPathSegment() + '|' + regexLastPathSegment());
  return getMatchingText(matchingText);
};

const extractCategoryNameLocal = pathname => {
  const matchingText = pathname.match(regexPathSegment() + '|' + regexPathSegment() + '|' + regexLastPathSegment());
  return getMatchingText(matchingText);
};

const main = {
  root: {
    to: '/'
  },
  landingPage: {
    to: landingPagePath,
    extractCategoryName
  },
  landingPageLocal: {
    to: landingPagePathLocal,
    extractCategoryNameLocal
  },
  home: {
    to: '/home'
  },
  welcome: {
    to: '/welcome/:token'
  },
  confirmEmail: {
    to: '/confirm/email/:token'
  },
  messagesArchived: {
    to: '/messages-archived'
  },
  messages: {
    to: '/messages'
  },
  messageChannel: {
    to: '/messages/:channelId'
  },
  mission: {
    to: '/mission/:missionId'
  },
  missionSlugWithCompanyLogged: {
    to: '/m/:missionSlugWithCompany'
  },
  missionSlugWithCompany: {
    to: '/missions/:missionSlugWithCompany'
  },
  notifications: {
    to: '/notifications'
  },
  taskOrder: {
    to: '/taskOrder/:taskOrderId'
  }
};

const general = {
  login: {
    icon: LogIn,
    to: '/login',
    label: 'login',
    hideFooter: true,
    showOnDesktopMenu: () => false,
    showOnMobileMenu: () => true
  },
  register: {
    icon: UserPlus,
    to: '/register',
    label: 'register',
    hideFooter: true,
    showOnDesktopMenu: () => false,
    showOnMobileMenu: () => true
  },
  resetPassword: {
    to: '/resetPassword/:token',
    label: 'resetPassword',
    hideFooter: true,
    hideMenu: () => true,
    showOnDesktopMenu: () => false,
    showOnMobileMenu: () => false
  },
  homePage: {
    icon: House,
    to: '/',
    label: 'home',
    hideWhenLogged: true,
    showOnFreelancePage: true,
    showOnDesktopMenu: () => false,
    showOnMobileMenu: () => true
  },
  homePageCompany: {
    icon: Suitcase,
    to: '/company',
    label: 'homePageCompany',
    role: 'company',
    hideForCompany: true,
    hideWhenLogged: true,
    showOnFreelancePage: true,
    showOnDesktopMenu: () => true,
    showOnMobileMenu: () => true
  },
  homePageFreelance: {
    icon: User,
    to: '/freelancer',
    label: 'homePageFreelance',
    role: 'freelance',
    hideWhenLogged: true,
    hideOnFreelancePage: true,
    showOnDesktopMenu: () => true,
    showOnMobileMenu: () => true
  },
  homePageSearch: {
    icon: Globe,
    to: '/search',
    label: 'homePageSearch',
    role: 'freelance',
    hideWhenLogged: true,
    hideOnFreelancePage: true,
    showOnDesktopMenu: () => true,
    showOnMobileMenu: () => true
  },
  pricing: {
    icon: Package,
    to: '/pricing',
    label: 'pricing',
    hideWhenLogged: true,
    showOnDesktopMenu: () => true,
    showOnMobileMenu: () => true
  },
  copyrightCalculator: {
    icon: Suitcase,
    to: '/copyright-benefits-calculator',
    label: 'copyrightBenefitsCalculator',
    hideWhenLogged: true,
    showOnDesktopMenu: () => true,
    showOnMobileMenu: () => true
  },
  partners: {
    icon: Users,
    to: '/partners',
    label: 'partners',
    hideWhenLogged: true,
    showOnDesktopMenu: () => true,
    showOnMobileMenu: () => true
  },
  faq: {
    icon: HelpCircle,
    to: '/faq/:role?', //The "?" is needed for react only
    label: 'faq',
    activePaths: ['/faq/'],
    hideWhenLogged: true,
    showOnDesktopMenu: () => true,
    showOnMobileMenu: () => true
  }
};

const legal = {
  legalCenter: {
    to: '/legal-center',
    label: 'legal:legalCenter'
  },
  privacyPolicy: {
    to: '/privacy-policy',
    label: 'legal:privacyPolicy'
  },
  termsOfUse: {
    to: '/legal-center',
    label: 'legal:terms'
  },
  whistleblowingPolicy: {
    to: '/whistleblowing-policy',
    label: 'legal:whistleblowingPolicy'
  },
  cookiesPolicy: {
    to: '/cookies-policy',
    label: 'legal:cookie'
  }
};

const administration = {
  community: {
    icon: Globe,
    to: '/community',
    label: 'common:community'
  },
  timesheet: {
    icon: Clock,
    to: '/timesheet',
    label: 'common:timesheet'
  },
  contract: {
    icon: File,
    to: '/contracts',
    label: 'common:contract'
  }
};

export const getGeneralRoutes = () => {
  // eslint-disable-next-line no-unused-vars
  const { copyrightCalculator, ...commonRoutesForAllCountries } = general;
  const { copyrightCalculator: _copyrightCalculator, ...commonRoutesForLU } = general;
  return territorySwitch({
    io: general,
    ch: commonRoutesForAllCountries,
    lu: commonRoutesForLU
  });
};

export default {
  ...main,
  general,
  legal,
  administration
};
