const displayOnlyKeys = process.env.NODE_ENV === 'test';
const isProduction = process.env.NEXT_PUBLIC_ENV === 'production';
const isWarningEnvironment = !isProduction;

const roundNbToNDecimals = decimalsAccepted => value => {
  const decimals = Number(decimalsAccepted);
  const number = Number(value);
  if (isNaN(number) || isNaN(decimals)) return 0;
  const factor = Math.pow(10, decimals);
  return Math.round(number * factor) / factor;
};

const getFormattedFileSize = bytes => {
  if (isNaN(bytes)) return '';

  const bytesPerKiloByte = 1024;
  const bytesPerMegaByte = 1048576;
  const roundToOneDecimal = roundNbToNDecimals(1);

  if (bytes < bytesPerKiloByte) return `${roundToOneDecimal(bytes)}B`;
  if (bytes < bytesPerMegaByte) return `${roundToOneDecimal(bytes / bytesPerKiloByte)}KB`;
  return `${roundToOneDecimal(bytes / bytesPerMegaByte)}MB`;
};

/**
 * Hack (PR is welcome on https://github.com/isaachinman/next-i18next/issues/1181)
 * otherwise it only takes the namespace from the "defaultLocale" (that could have missing translations)
 */
const namespaces = require(`${__dirname}/src/translate/locales/namespaces.json`);
module.exports = {
  i18n: {
    localeDetection: true,
    defaultLocale: process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE,
    locales: process.env.NEXT_PUBLIC_AVAILABLE_LANGUAGES.split(',')
  },
  localePath: require('path').resolve('./public/locales'),
  ns: namespaces,
  debug: false,
  fallbackLng: process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE.split('-')[0],
  react: {
    useSuspense: false
  },
  use: [require('i18next-intervalplural-postprocessor')],
  serializeConfig: false, // because of the custom use i18next plugin
  appendNamespaceToMissingKey: true,
  parseMissingKeyHandler: key => (displayOnlyKeys ? key : isWarningEnvironment ? `⚠️${key}⚠️` : ''),
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default,
    format: function (value, format) {
      if (format === 'bytesToMb') return getFormattedFileSize(value);
      return value;
    }
  }
};
