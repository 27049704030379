import React from 'react';
import styled from 'styled-components';
import { typography, color } from 'styled-system';
const StyledSvg = styled('svg')(typography, color);

const House = props => (
  <StyledSvg width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5 22.818c-1.657 0-3-1.332-3-2.975V8.933c0-.305.142-.594.386-.782l9-6.942a1.007 1.007 0 011.228 0l9 6.942a.989.989 0 01.386.783v10.909c0 1.643-1.343 2.975-3 2.975H5zm7-19.57l-8 6.17v10.425c0 .548.448.992 1 .992l2.999-.001L8 11.909c0-.509.386-.928.883-.985L9 10.917h6c.552 0 1 .444 1 .992l-.001 8.925H19c.552 0 1-.443 1-.991V9.419l-8-6.17zm2 9.653h-4l-.001 7.933h4L14 12.901z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </StyledSvg>
);
export default House;
