import i18n from 'i18next';
import Locize from 'i18next-locize-backend';
import Backend from 'i18next-xhr-backend';
import { pipe, split, nth, toLower } from 'ramda';
import { withTranslation, initReactI18next, useTranslation } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import cookie from 'react-cookies';
import {
  isTranslationWarningActivated,
  isTranslationDebugActivated,
  getApiTranslationsProjectId,
  i18nConfig,
  getPlatformLanguages,
  getPlatformDefaultLanguage,
  isNextJsMode
} from '../utils/config';
import territorySwitch from '../components/territory/territorySwitch';
import { getFormattedFileSize } from '../utils/pureUtils';
import * as cachedResources from './locales/cached.json';
import { isInBrowser } from '../utils/reactOrNext';
import { useTranslation as useNextjsTranslation, withTranslation as withNextJsTranslation } from 'next-i18next';

const getLanguageOrDefault = lng => {
  const prefixLng = pipe(split('-'), nth(0), toLower)(lng);
  const languageFound = getPlatformLanguages().find(item => item.startsWith(prefixLng));

  return languageFound || getPlatformDefaultLanguage();
};

const browserLng = (isInBrowser() && window?.navigator?.language) || getPlatformDefaultLanguage();
const isWarningEnvironment = isTranslationWarningActivated();

if (!isNextJsMode()) {
  if (!window.localStorage.getItem('storedLanguage')) {
    window.localStorage.setItem('storedLanguage', getLanguageOrDefault(browserLng));
  }
  const initLanguage = () =>
    territorySwitch({
      ch: getPlatformDefaultLanguage(),
      lu: window.localStorage.getItem('storedLanguage'),
      io: window.localStorage.getItem('storedLanguage')
    });
  const displayOnlyKeys = process.env.NODE_ENV === 'test';
  const getFallBackLanguage = () => {
    if (isWarningEnvironment) {
      return 'dev';
    }
    return getPlatformDefaultLanguage();
  };
  const otherI18nInfo = {};
  otherI18nInfo.resources = i18nConfig.isUsingLocizeTranslation() || displayOnlyKeys ? {} : cachedResources.default;
  if (!displayOnlyKeys) {
    if (i18nConfig.isUsingLocizeTranslation()) {
      i18n.use(Locize);
      otherI18nInfo.backend = { projectId: `${getApiTranslationsProjectId()}` };
    } else {
      i18n.use(Backend);
      otherI18nInfo.backend = { loadPath: '/locales/{{lng}}/{{ns}}.json' };
    }
  }

  i18n
    .use(initReactI18next) // bind react-i18next to the instance
    .use(intervalPlural)
    .init({
      ...otherI18nInfo,
      react: {
        useSuspense: false
      },
      partialBundledLanguages: true,
      lng: initLanguage(),
      fallbackLng: getFallBackLanguage(),
      debug: isTranslationDebugActivated(),
      defaultNS: ['common'],
      saveMissing: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default,
        format: function (value, format) {
          if (format === 'bytesToMb') return getFormattedFileSize(value);
          return value;
        }
      },
      appendNamespaceToMissingKey: true,
      parseMissingKeyHandler: key => (displayOnlyKeys ? key : isWarningEnvironment ? `👹👹${key}👹👹` : '')
    });
}

/**
 * @deprecated  Use useTranslate hook instead, really important to hide "$" translations!
 */
export const translate = (...args) => {
  if (isNextJsMode()) {
    return withNextJsTranslation(...args);
  }
  return withTranslation(...args);
};

export const getCustomT = initialT => (translationPath, params) => {
  const translation = initialT(translationPath, params);
  if (translation === '$') {
    return '';
  }
  return translation;
};

export const useTranslate = (...args) => {
  if (isNextJsMode()) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t, i18n, ready } = useNextjsTranslation(...args);
    return { t: getCustomT(t), i18n, ready };
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t, i18n, ready } = useTranslation(...args);

  return { t: getCustomT(t), i18n, ready };
};

/**
 * @deprecated use {changeUserLanguage}
 * @param newLng
 */
export const setUserLanguage = newLng => changeUserLanguage(i18n, newLng);
export const changeUserLanguage = (i18n, newLng) => {
  const storedLanguage = newLng.includes('-') ? newLng : newLng.toLowerCase();
  if (isNextJsMode()) {
    cookie.save('NEXT_LOCALE', storedLanguage, { path: '/' });
  } else {
    i18n.changeLanguage(storedLanguage);
    window.localStorage.setItem('storedLanguage', storedLanguage);
  }
  return storedLanguage;
};

export const translateArray = (t, key) => {
  const translation = t(key, { returnObjects: true });
  if (translation instanceof Array) {
    return translation;
  }
  return [];
};

export default i18n;
