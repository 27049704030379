import { getDaysInMonth, setDate, eachDay } from 'date-fns';
import { cond, T, propEq, path, pathOr, find, findIndex, dropLast, init, take, dissocPath, assocPath } from 'ramda';
import { STATUS } from '../../constants';
import { toastNotification } from '../../utils/utils';
import { isArray } from '../../utils/validator';
import BeelanceDate from '../../utils/BeelanceDate';

export function getDaysArrayByMonth(date) {
  const numberOfDaysInMonth = getDaysInMonth(date);

  return eachDay(setDate(date, 1), setDate(date, numberOfDaysInMonth));
}

/**
 * @param {string[]} isoDates
 * @returns {{ minDate: string, maxDate: string }}
 */
export const getMinAndMaxDates = function (isoDates) {
  let minValue = isoDates[0];
  let maxValue = isoDates[0];
  let minDate = BeelanceDate.fromISOString(minValue).getDate();
  let maxDate = BeelanceDate.fromISOString(maxValue).getDate();

  isoDates.forEach(isoDate => {
    const date = BeelanceDate.fromISOString(isoDate).getDate();
    if (date > maxDate) {
      maxDate = date;
      maxValue = isoDate;
    }
    if (date < minDate) {
      minDate = date;
      minValue = isoDate;
    }
  });

  return { minDate: minValue, maxDate: maxValue };
};

const isMidnightIn12HourFormat = (period, time) => () => period === 'AM' && time >= 12 && time < 13;
const isAfterMiddayIn12HourFormat = (period, time) => () => period === 'PM' && time >= 1 && time < 12;

const convertTo24HoursFormat = (period, time) => {
  const timeNb = Number(time);
  return cond([
    [isMidnightIn12HourFormat(period, time), () => timeNb - 12],
    [isAfterMiddayIn12HourFormat(period, time), () => timeNb + 12],
    [T, () => timeNb]
  ])();
};

const isMidnightIn24HourFormat = time => time >= 0 && time < 1;
const isMiddayIn24HourFormat = time => time >= 12 && time < 13;
const isAfterMiddayIn24HourFormat = time => time >= 13;

const create12HoursObject = (period, time, isAM) => () => ({
  [period]: time,
  [`${period}Period`]: isAM ? 'AM' : 'PM'
});

const convertTo12HoursFormat = (time, isStartHour) => {
  const period = isStartHour ? 'from' : 'to';
  const timeNb = Number(time);

  return cond([
    [isMidnightIn24HourFormat, create12HoursObject(period, timeNb + 12, true)],
    [isMiddayIn24HourFormat, create12HoursObject(period, timeNb, false)],
    [isAfterMiddayIn24HourFormat, create12HoursObject(period, timeNb - 12, false)],
    [T, create12HoursObject(period, timeNb, true)]
  ])(timeNb);
};

const getExtratimeFieldsValues = ({ startHour, endHour }) => ({
  ...convertTo12HoursFormat(startHour, true),
  ...convertTo12HoursFormat(endHour)
});

export const getStartAndEndHours = ({ fromPeriod, from, toPeriod, to }) => {
  const [startHour, endHour] = [convertTo24HoursFormat(fromPeriod, from), convertTo24HoursFormat(toPeriod, to)];
  return { startHour, endHour };
};

export const convertExtraTimeForBE = timeslots => timeslots.map(getStartAndEndHours);
export const convertExtraTimeForFE = timeslots => timeslots.map(getExtratimeFieldsValues);

export const getExtraTimeDuration = ({ startHour, endHour }) => endHour - startHour;

const {
  TIMESHEET: { TO_APPROVE, TO_CORRECT, TO_SUBMIT, APPROVED }
} = STATUS;

const statusTS = [
  [TO_APPROVE, 'toApprove'],
  [TO_CORRECT, 'toCorrect'],
  [TO_SUBMIT, 'toSubmit'],
  [APPROVED, 'approved']
];

export const getListName = currentStatus => find(([status]) => status === currentStatus, statusTS)[1];
export const getItemIndex = (id, listPath, list) => findIndex(propEq('id', id))(path(listPath, list));

export const transferMonthlyTimesheet = (isFreelance, successTranslationKey, action, state, translate) => {
  const { level1Id, level2Id, level3Id, currentStatus, updatedStatus } = action;

  const removeFromListStatus = getListName(currentStatus);
  const removeFromList = state[removeFromListStatus];

  const appendToListStatus = getListName(updatedStatus);
  const appendToList = state[appendToListStatus];

  // GET PATH TO CURRENT MONTHLY IN CURRENT LIST + PATH TO LIST WHERE TO PUSH UPDATED MONTHLY
  const pathToMonthly = isFreelance
    ? [
        [level1Id, ['projects']],
        [level2Id, ['missions']],
        [level3Id, []]
      ]
    : [
        [level1Id, ['missions']],
        [level2Id, ['monthlyTimeSheets']],
        [level3Id, []]
      ];
  const listPaths = pathToMonthly.reduce(
    ({ removeFromPath, appendToPath }, [id, key]) => {
      const appendToListIndex = getItemIndex(id, appendToPath, appendToList);
      const removeFromListIndex = getItemIndex(id, removeFromPath, removeFromList);

      return {
        removeFromPath: [...removeFromPath, removeFromListIndex, ...key],
        appendToPath: appendToListIndex !== -1 ? [...appendToPath, appendToListIndex, ...key] : appendToPath
      };
    },
    { removeFromPath: [], appendToPath: [] }
  );

  // Example of the expected removeFromPath:
  //    - For a freelance: [2, 'projects', 6, 'missions', 1]
  //    - For a company: [2, 'missions', 6, 'monthlyTimeSheets', 1]
  // Example of the expected appendToPath:
  //    - For a freelance:
  //        - If doesn't contain the same company: []
  //        - If contains the same company but not the same project: [1, 'projects']
  //        - If contains the same project: [2, 'projects', 6, 'missions']
  //    - For a company:
  //        - If doesn't contain the same project: []
  //        - If contains the same project but not the same mission: [1, 'missions']
  //        - If contains the same mission: [2, 'missions', 6, 'monthlyTimeSheets']
  const { removeFromPath, appendToPath } = listPaths;

  // IF THE CURRENT LIST DOESN'T CONTAINS OTHER COMPANIES/PROJECTS/MISSIONS/MONTHLYTIMEHSEETS, REMOVE THE COMPANY/PROJECT/MISSION/MONTHLYTIMEHSEET
  function updateRemoveFromList(index = 0) {
    if (index === removeFromPath.length) return [];

    const pathToItemIndex = dropLast(index * 2, removeFromPath);
    const pathToNestedList = init(pathToItemIndex);
    const currentNestedList = pathOr([], pathToNestedList, removeFromList);

    if (currentNestedList.length <= 1) {
      return updateRemoveFromList(++index);
    }
    return currentNestedList[0] && !!pathToItemIndex.length ? dissocPath(pathToItemIndex, removeFromList) : [];
  }

  const removeFromListUpdated = updateRemoveFromList();

  // CLONE CURRENT PARENT OBJECT (COMPANY FOR FREELANCE / PROJECT FOR COMPANY USER) AND KEEP ONLY THE CURRENT ITEMS IN EACH LIST
  const ids = isFreelance
    ? ['projects', level2Id, 'missions', level3Id]
    : ['missions', level2Id, 'monthlyTimeSheets', level3Id];
  const cleanCompanyOrProject = removeFromPath.reduce((acc, __, index) => {
    const currentPath = take(index + 1, removeFromPath);
    const currentPosition = path(currentPath, acc);
    return isArray(currentPosition)
      ? assocPath(currentPath, [currentPosition.find(item => item.id === ids[index])], acc)
      : acc;
  }, state[removeFromListStatus]);

  // UPDATE MONTHLY TIMESHEET STATUS OF THE CLONED OBJECT
  const pathToMonthlyStatus = isFreelance
    ? ['projects', 0, 'missions', 0, 'monthlyTimeSheets', 0, 'status']
    : ['missions', 0, 'monthlyTimeSheets', 0, 'status'];
  const updatedCompanyOrProject = assocPath(pathToMonthlyStatus, updatedStatus, cleanCompanyOrProject[0]);

  // GET ONLY THE COMPANY/PROJECT/MISSION/MONTHLYTIMESHEET OBJECT DEPENDING ON WHAT THE 'APPEND TO' LIST ALREADY CONTAINS
  const pathToMonthlyClean = isFreelance ? ['projects', 0, 'missions', 0] : ['missions', 0, 'monthlyTimeSheets', 0];
  const itemToPush = path(take(appendToPath.length, pathToMonthlyClean), updatedCompanyOrProject);

  // PUSH THE OBJECT IN THE RELATED LIST (COMPANIES/PROJECTS/MISSIONS/MONTHLYTIMESHEETS) OF THE LIST 'APPEND TO'
  const updatedItemsList = [...path(appendToPath, appendToList), itemToPush];

  // IF THE 'APPEND TO' LIST HAS NO COMPANIES(if freelance)/PROJECTS(if company user), PUSH THE UPDATED LIST,
  // OTHERWISE, ASSOC THE UPDATED LIST (PROJECTS/MISSIONS/MONTHLYTIMESHEETS) TO THE RELATED PATH OF THE 'APPEND TO' LIST
  const appendToListUpdated = appendToPath.length
    ? assocPath(appendToPath, updatedItemsList, appendToList)
    : updatedItemsList;

  toastNotification('success', 'timesheet', successTranslationKey, translate);

  return {
    [appendToListStatus]: appendToListUpdated,
    [removeFromListStatus]: removeFromListUpdated,
    isFetching: false,
    hasError: false
  };
};
