import House from '../components/atoms/icons/House';
import User from '../components/atoms/icons/User';

const main = {
  missions: {
    icon: House,
    to: '/dashboard',
    label: 'common:dashboard'
  },
  search: {
    to: '/m',
    label: 'common:searchMission'
  },
  profile: {
    icon: User,
    to: '/profile',
    label: 'common:yourProfile'
  },
  linkedInImport: {
    to: '/linkedin-import'
  }
};

const dashboard = {
  appliedMissions: {
    to: '/applied-missions',
    label: 'common:appliedMissions',
    prop: 'appliedMissions'
  },
  currentMissions: {
    to: '/current-missions',
    label: 'common:currentMissions',
    prop: 'currentMissions'
  },
  pastMissions: {
    to: '/past-missions',
    label: 'common:pastMissions',
    prop: 'pastMissions'
  }
};

const settings = {
  verifyAccount: {
    icon: 'check-circle',
    to: '/settings/verify-account',
    label: 'settings:verifyAccount',
    shouldBeDisplayed: profile => !profile?.validationProgress?.isVerified
  }
};

export default {
  ...main,
  dashboard,
  settings
};
