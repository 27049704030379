export default {
  main: '#21bc90',
  mainDark: '#198c6b',
  mainLight: '#35dbac',
  secondary: '#ed6001',
  secondaryLight: '#fe8c3f',
  blue: '#00a7ff',
  red: '#fe3f52',
  black: '#000000',
  white: '#ffffff',
  whiteGrey: '#F9F9FB',
  greyVerylight: '#f1f1f1',
  greyLight: '#c5c5c5',
  greyer: '#d3d4d6',
  grey: '#b3b3b3',
  grey150: '#e3e4e6',
  grey600: '#5c5f61',
  greyDark: '#767676',
  greyBluedark: '#505358',
  greyBluedarker: '#434a54',
  dark: '#111517',
  openwork: '#ff7800',
  beelanceServices: '#134b9b',
  securex: '#4c2d68',
  transparent: 'transparent',
  linkedin: '#0077B5',
  twitter: '#36A6FA',
  facebook: '#3b5998',
  github: '#333333',
  stackoverflow: '#f48024',
  dribbble: '#F95E96',
  instagram: '#E4405F',
  transparents: [
    'rgba(116, 116, 128, 0.08)',
    'rgba(118, 118, 128, 0.12)',
    'rgba(120, 120, 128, 0.16)',
    'rgba(235, 235, 245, 0.18)',
    'rgba(116, 116, 128, 0.18)',
    'rgba(60, 60, 67, 0.18)',
    'rgba(118, 118, 128, 0.24)',
    'rgba(60, 60, 67, 0.29)',
    'rgba(60, 60, 67, 0.3)',
    'rgba(235, 235, 245, 0.3)',
    'rgba(120, 120, 128, 0.32)',
    'rgba(120, 120, 128, 0.36)',
    'rgba(84, 84, 88, 0.65)'
  ]
};
