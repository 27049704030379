const main = {
  account: {
    icon: 'user',
    to: '/settings/account',
    label: 'settings:accountDetails'
  },
  company: {
    icon: 'briefcase',
    to: '/settings/company',
    label: 'settings:companyInfo'
  },
  security: {
    icon: 'lock',
    to: '/settings/security',
    label: 'settings:secuAndPassword'
  }
};

export default { ...main };
